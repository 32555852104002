import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EXPORT_FORMAT, PAYMENT_REQUEST_EXPORT_TAB } from "src/constants";
import {
    Company,
    FixMeLater,
    FolderNode,
    OptinsExportState,
    PaymentRequestExportState,
    ReturnDocument,
    ReturnDocumentNoteState,
    ReturnNode,
    ReturnPage,
    ReturnTemplate,
    State,
    Tree,
} from "src/types";
import { AttachmentsState } from "src/types/Attachments.types";
import { number } from "prop-types";

interface PremiumState {
    value: {
        company?: Company;
        state?: State;
        folderNode?: FolderNode;
        returnDocument?: ReturnDocument;
        returnTemplate?: ReturnTemplate;
        selectedReturnPage?: ReturnPage;
        optinsExport: OptinsExportState;
        paymentRequestExport: PaymentRequestExportState;
        returnNode?: ReturnNode;
        tree?: Tree;
        attachments: AttachmentsState;
        returnNotes: ReturnDocumentNoteState;
        selectedJurisdictionForSBP?: number;
    };
}

const initialState: PremiumState = {
    value: {
        company: undefined,
        state: undefined,
        folderNode: undefined,
        returnDocument: undefined,
        returnTemplate: undefined,
        selectedReturnPage: undefined,
        optinsExport: {
            open: false,
            folderNode: undefined,
            returnNode: undefined,
            selectedTab: "Instructions",
            compare: [],
        },
        paymentRequestExport: {
            open: false,
            selectedTab: PAYMENT_REQUEST_EXPORT_TAB.GENERAL,
            scheduleTemplateDTO: undefined,
            payload: {
                paymentTypes: [],
                minApproveDate: "",
                maxApproveDate: "",
                minDueDate: "",
                maxDueDate: "",
                dueDateRange: true,
                includeHeader: false,
                delimiter: ",",
                fixedWidth: [],
                minPaymentAmount: 0,
                exportInUpperCase: false,
                markAsPaid: false,
                fillDataPaidWithToday: false,
                useLeadingZero: false,
                fieldsToExport: [],
                exportFormat: EXPORT_FORMAT.DELIMITED,
            },
        },
        returnNode: undefined,
        tree: undefined,
        attachments: {
            open: false,
        },
        returnNotes: {
            newFieldNoteId: undefined,
            newFieldNoteType: undefined as FixMeLater,
            showNotes: false,
            focusedFieldNoteId: undefined,
            focusedFieldNoteType: undefined as FixMeLater,
        },
        selectedJurisdictionForSBP: 1
    },
};

export const PremiumSlice = createSlice({
    name: "Premium",
    initialState,
    reducers: {
        setCompany: (state, action: PayloadAction<Company>) => {
            state.value.company = action.payload;
        },
        setState: (state, action: PayloadAction<State>) => {
            state.value.state = action.payload;
        },
        setFolderNode: (state, action: PayloadAction<FolderNode>) => {
            state.value.folderNode = action.payload;
        },
        setReturnDocument: (state, action: PayloadAction<ReturnDocument>) => {
            state.value.returnDocument = action.payload;
        },
        setReturnTemplate: (state, action: PayloadAction<ReturnTemplate>) => {
            state.value.returnTemplate = action.payload;
        },
        setSelectedReturnPage: (state, action: PayloadAction<ReturnPage>) => {
            state.value.selectedReturnPage = action.payload;
        },
        setOptinsExport: (state, action: PayloadAction<OptinsExportState>) => {
            state.value.optinsExport = action.payload;
        },
        setPaymentRequestExport: (
            state,
            action: PayloadAction<PaymentRequestExportState>,
        ) => {
            state.value.paymentRequestExport = action.payload;
        },
        setReturnNode: (state, action: PayloadAction<ReturnNode>) => {
            state.value.returnNode = action.payload;
        },
        setTree: (state, action: PayloadAction<Tree>) => {
            state.value.tree = action.payload;
        },
        setAttachments: (state, action: PayloadAction<AttachmentsState>) => {
            state.value.attachments = action.payload;
        },
        setReturnNotes: (
            state,
            action: PayloadAction<ReturnDocumentNoteState>,
        ) => {
            state.value.returnNotes = action.payload;
        },
        setSelectedJurisdictionForSBP: (state, action: PayloadAction<number>) => {
            state.value.selectedJurisdictionForSBP = action.payload;
        },
    },
});

export const {
    setCompany,
    setState,
    setFolderNode,
    setReturnDocument,
    setReturnTemplate,
    setSelectedReturnPage,
    setOptinsExport,
    setPaymentRequestExport,
    setReturnNode,
    setTree,
    setAttachments,
    setReturnNotes,
    setSelectedJurisdictionForSBP
} = PremiumSlice.actions;
export default PremiumSlice.reducer;
