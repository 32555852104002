import { FixMeLater, YearData } from "src/types";

export const calculateQuarterlyUsageSummary = (yearData: YearData[]) => {
    const quarterlyUsageSummary: { [year: string]: number[] } = {};

    yearData?.forEach((yearInfo) => {
        const { year, usageList } = yearInfo;

        quarterlyUsageSummary[year] = [0, 0, 0, 0];

        usageList.forEach((userUsage) => {
            const { clicksByQuarter } = userUsage;

            clicksByQuarter.forEach((quarterClicks, index) => {
                quarterlyUsageSummary[year][index] += quarterClicks;
            });
        });
    });

    return quarterlyUsageSummary;
};

const monthNames: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const quarterNames: string[] = ["Q1", "Q2", "Q3", "Q4"];

interface DataGridInput {
    columns: FixMeLater[];
    rows: FixMeLater[];
}

export const calculateMonthlyWithIp = (yearData: YearData[]) => {
    const monthlyWithIp: DataGridInput = {
        columns: [],
        rows: [],
    };

    const columns = [
        "Year",
        "IP Address",
        "Total Clicks Purchased",
        "Total Used Clicks",
        "Good Clicks",
        "Bad Clicks",
        ...monthNames,
    ];

    columns.forEach((col, index) => {
        monthlyWithIp.columns.push({ id: index, field: col, headerName: col });
    });

    yearData.forEach((yearInfo, yearIndex) => {
        const { year, purchasedClicks, usageList } = yearInfo;
        usageList.forEach((usage) => {
            const { ipAddress, goodClicks, badClicks, clicksByMonth } = usage;

            const row = {
                Year: year,
                "IP Address": ipAddress,
                "Total Clicks Purchased": purchasedClicks,
                "Total Used Clicks": goodClicks + badClicks,
                "Good Clicks": goodClicks,
                "Bad Clicks": badClicks,
            };

            monthNames.forEach((month, index) => {
                row[month] = clicksByMonth[index];
            });

            monthlyWithIp.rows.push({ id: yearIndex, ...row });
        });
    });

    return monthlyWithIp;
};

export const calculateMonthlyWithoutIp = (yearData: YearData[]) => {
    const monthlyWithoutIp: DataGridInput = {
        columns: [],
        rows: [],
    };

    const columns = [
        "Year",
        "Total Clicks Purchased",
        "Total Used Clicks",
        "Good Clicks",
        "Bad Clicks",
        ...monthNames,
    ];

    columns.forEach((col, index) => {
        monthlyWithoutIp.columns.push({
            id: index,
            field: col,
            headerName: col,
        });
    });

    yearData.forEach((yearInfo, yearIndex) => {
        const { year, purchasedClicks, totalUsedClicks, usageList } = yearInfo;

        const row = {
            Year: year,
            "Total Clicks Purchased": purchasedClicks,
            "Total Used Clicks": totalUsedClicks,
            "Good Clicks": 0,
            "Bad Clicks": 0,
        };

        monthNames.forEach((month) => {
            row[month] = 0;
        });

        usageList.forEach((usage) => {
            const { goodClicks, badClicks, clicksByMonth } = usage;

            row["Good Clicks"] += goodClicks;
            row["Bad Clicks"] += badClicks;

            monthNames.forEach((month, index) => {
                row[month] += clicksByMonth[index];
            });
        });

        monthlyWithoutIp.rows.push({ id: yearIndex, ...row });
    });

    return monthlyWithoutIp;
};

export const calculateQuarterlyWithIp = (yearData: YearData[]) => {
    const quarterlyWithIp: DataGridInput = {
        columns: [],
        rows: [],
    };

    const columns = [
        "Year",
        "IP Address",
        "Total Clicks Purchased",
        "Total Used Clicks",
        "Good Clicks",
        "Bad Clicks",
        ...quarterNames,
    ];

    columns.forEach((col, index) => {
        quarterlyWithIp.columns.push({
            id: index,
            field: col,
            headerName: col,
        });
    });

    yearData.forEach((yearInfo, yearIndex) => {
        const { year, purchasedClicks, usageList } = yearInfo;
        usageList.forEach((usage) => {
            const { ipAddress, goodClicks, badClicks, clicksByQuarter } = usage;

            const row = {
                Year: year,
                "IP Address": ipAddress,
                "Total Clicks Purchased": purchasedClicks,
                "Total Used Clicks": goodClicks + badClicks,
                "Good Clicks": goodClicks,
                "Bad Clicks": badClicks,
                Q1: 0,
                Q2: 0,
                Q3: 0,
                Q4: 0,
            };

            quarterNames.forEach((quarter, index) => {
                row[quarter] = clicksByQuarter[index];
            });

            quarterlyWithIp.rows.push({ id: yearIndex, ...row });
        });
    });

    return quarterlyWithIp;
};

export const calculateQuarterlyWithoutIp = (yearData: YearData[]) => {
    const quarterlyWithoutIp: DataGridInput = {
        columns: [],
        rows: [],
    };

    const columns = [
        "Year",
        "Total Clicks Purchased",
        "Total Used Clicks",
        "Good Clicks",
        "Bad Clicks",
        ...quarterNames,
    ];

    columns.forEach((col, index) => {
        quarterlyWithoutIp.columns.push({
            id: index,
            field: col,
            headerName: col,
        });
    });

    yearData.forEach((yearInfo, yearIndex) => {
        const { year, purchasedClicks, totalUsedClicks, usageList } = yearInfo;

        const row = {
            Year: year,
            "Total Clicks Purchased": purchasedClicks,
            "Total Used Clicks": totalUsedClicks,
            "Good Clicks": 0,
            "Bad Clicks": 0,
            Q1: 0,
            Q2: 0,
            Q3: 0,
            Q4: 0,
        };

        quarterNames.forEach((quarter) => {
            row[quarter] = 0;
        });

        usageList.forEach((usage) => {
            const { goodClicks, badClicks, clicksByQuarter } = usage;

            row["Good Clicks"] += goodClicks;
            row["Bad Clicks"] += badClicks;

            quarterNames.forEach((quarter, index) => {
                row[quarter] += clicksByQuarter[index];
            });
        });

        quarterlyWithoutIp.rows.push({ id: yearIndex, ...row });
    });

    return quarterlyWithoutIp;
};
