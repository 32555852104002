import React, { PropsWithChildren } from "react";
import { FieldToolbarStyled } from "./FieldToolbar.styled";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

interface FieldToolbarProps {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    fieldStyles: React.CSSProperties;
    inputId: string;
}

const FieldToolbar: React.FC<PropsWithChildren<FieldToolbarProps>> = ({
    setVisible,
    fieldStyles,
    children,
    inputId,
}) => {
    const removePx = (value: string): number => {
        return Number(value.replace("px", ""));
    };

    let positionStyles: React.CSSProperties = {};
    positionStyles.position = "absolute";
    positionStyles.left = `${removePx(fieldStyles.left + "") + removePx(fieldStyles.width + "")}px`;
    positionStyles.top = `${removePx(fieldStyles.top + "") - removePx(fieldStyles.height + "") / 2}px`;

    React.useEffect(() => {
        const handleMouseOver = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            setVisible(target.id === inputId || !!target.closest(".field-toolbar"))
        };

        // Add the event listener when the component is mounted
        document.addEventListener("mouseover", handleMouseOver);

        return () => {
            // Cleanup the event listener when the component is unmounted
            document.removeEventListener("mouseover", handleMouseOver);
        };
    }, []);

    return (
        <FieldToolbarStyled className="field-toolbar" style={positionStyles}>
            {children}
            <IconButton
                onClick={() => setVisible(false)}
                size="small"
                aria-label="close"
            >
                <Close
                    fontSize="small"
                    sx={{
                        color: "#354560",
                    }}
                />
            </IconButton>
        </FieldToolbarStyled>
    );
};

export default FieldToolbar;
