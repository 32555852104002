import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TabProperties, TabsPropertiesMap } from "src/types";

interface Tabs {
    tabsProperties: TabsPropertiesMap;
    splitScreen: boolean;
    returnOrScheduleJustGotUpdated: boolean;
}

const initialState: Tabs = {
    tabsProperties: {},
    splitScreen: false,
    returnOrScheduleJustGotUpdated: false,
};

export const TabSlice = createSlice({
    name: "Tabs",
    initialState,
    reducers: {
        setTabs: (state, action: PayloadAction<TabsPropertiesMap>) => {
            state.tabsProperties = action.payload;
        },
        setTab: (state, action: PayloadAction<TabProperties>) => {
            const tab = action.payload;
            state.tabsProperties[tab.id] = tab;
        },
        removeTab: (state, action: PayloadAction<string>) => {
            delete state.tabsProperties[action.payload];
        },
        setSplitScreen: (state, action: PayloadAction<boolean>) => {
            state.splitScreen = action.payload;
        },
        setReturnOrScheduleJustGotUpdated: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.returnOrScheduleJustGotUpdated = action.payload;
        },
    },
});

export const {
    setTabs,
    setTab,
    removeTab,
    setSplitScreen,
    setReturnOrScheduleJustGotUpdated,
} = TabSlice.actions;
export default TabSlice.reducer;
