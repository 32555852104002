import { Box, Button, Card, CardContent, IconButton } from "@mui/material";
import styled from "styled-components";

interface StyledCardProps {
    $noteType?: string;
}

export const StyledCard = styled(Card)<StyledCardProps>`
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-left: 4px solid
        ${(props) => {
            if (props.$noteType !== "P") return "#B7CCE7";
            return "#e0e0e0";
        }};
    min-height: 100px;
    margin: 16px;
    max-height: 200px;
    width: 220px;
    position: relative;
    &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
    }
`;

export const StyledCardContent = styled(CardContent)`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledCloseButton = styled(IconButton)`
    position: absolute;
    right: 8px;
    top: 8px;
    color: #757575;
`;

export const StyledEditButton = styled(IconButton)`
    position: absolute;
    right: 32px;
    top: 8px;
    color: #757575;
`;

export const StyledBox = styled(Box)`
    overflow-y: auto;
    height: 100%;
    padding: 16px;
`;

export const NoteTextWrapper = styled.div`
    max-height: 100px;
    overflow: auto;
    width: 188px;
`;

export const StyledAddButton = styled(IconButton)`
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    height: 40px;
    width: 40px;

    &:hover {
        background-color: #f0f0f0;
    }
`;

export const StyledSaveCheckBox = styled(Button)`
    border-radius: 50%;
    min-width: 32px;
    width: 32px;
`;
