import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ErrorLog.scss";
import { Typography } from "@mui/material";
import { AllocatorService } from "src/services";
import { AllocatorErrorLog } from "src/types";
import Loader from "src/components/Loader/Loader";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";

const ErrorLog: FC = () => {
    const allocatorService = AllocatorService.getInstance();

    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorLog, setErrorLog] = useState<AllocatorErrorLog | null>(null);
    const { jobId } = useParams();

    useEffect(() => {
        const fetchJobErrorLog = async () => {
            setIsLoading(true);

            try {
                const errorLogResponse = await allocatorService.getJobErrorLog(
                    Number(jobId)
                );
                setErrorLog(errorLogResponse);
            } catch (error) {
                console.error("Error fetching Job Error Log", error);
                setError(error as Error | null);
            } finally {
                setIsLoading(false);
            }
        };

        fetchJobErrorLog();
    }, []);

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching Job Error Log`}
            />
    );

    if (!error && isLoading) return <Loader />;

    return (
        <div className="error-log-container">
            <div className="error-log-landing">
                <h6>{errorLog?.filename} error log</h6>
                {
                    !errorLog?.errorDetails ? (
                        <Typography sx={{marginTop: 1 }}>
                            Unexpected server error.
                        </Typography>
                    ) : (
                        <>
                            <ul>
                            {
                                errorLog?.errorDetails?.errors?.map((error, index) => {
                                    return (
                                        <li key={index}>Line #{error?.rowNumber} {error?.errorMessage}</li>
                                    )
                                })
                            }
                            </ul>
                            <Typography sx={{marginTop: 1 }}>
                                {errorLog?.errorDetails?.rowsProcessed} records read successfully.
                            </Typography>
                            <Typography>
                                {errorLog?.errorDetails?.rowsFailed} records could not be read.
                            </Typography>
                            <Typography>
                                {errorLog?.errorDetails?.totalRows} records processed.
                            </Typography>
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default ErrorLog;
