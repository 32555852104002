import { Delete } from "@mui/icons-material";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Modal } from "@mui/material";
import {
    DataGridPremium,
    GridCellParams,
    GridEditInputCell,
} from "@mui/x-data-grid-premium";
import React, { ChangeEvent, DragEvent } from "react";
import { ReturnDocumentService } from "src/services";
import { AttachmentsService } from "src/services/AttachmentsService";
import {
    ReturnDocumentAndValidationsDTO,
    ReturnDocument,
    DataGridRow,
    AttachmentMetadata,
} from "src/types";
import "./Attachments.scss";
import { FileUploadContainer, FileUploadRectangle } from "./Attachments.styled";
import { validateAttachmentFile } from "./Attachments.util";
import { infoIcons } from "./InfoIcons";
import { getQueryParams } from "src/utils";

interface AttachmentModalProps {
    returnDocument: ReturnDocument;
    setReturnDocument;
}

const Attachments: React.FC<AttachmentModalProps> = ({
    returnDocument,
    setReturnDocument,
}) => {
    const attachmentsService = AttachmentsService.getInstance();
    const returnDocumentService = ReturnDocumentService.getInstance();

    const getReturnId = () =>
        getQueryParams({
            companyId: returnDocument?.id?.companyId,
            folderId: returnDocument?.id?.folderId,
            productId: returnDocument?.id?.productId,
            taxYearId: returnDocument?.id?.taxYearId,
            moduleId: returnDocument?.id?.moduleId,
            returnId: returnDocument?.id?.returnId,
            retalFolderId: returnDocument?.id?.retalFolderId,
            taxYear: returnDocument?.id?.taxYearId,
        });

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy";

        const dropArea = event.currentTarget as HTMLDivElement;
        dropArea.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        const dropArea = event.currentTarget as HTMLDivElement;
        dropArea.style.backgroundColor = "rgba(0, 0, 0, 0)";
    };

    const uploadAttachment = async (
        file: File,
        id: number | null,
        type: "SYSTEM_DEFINED" | "USER_DEFINED",
    ) => {
        const formData = new FormData();
        formData.append("file", file);

        const attachment = {
            id: id,
            fileIdentifier: "",
            type: type,
            customFileName: file.name,
            description: "",
        };

        const attachmentMetadata = new Blob(
            [
                JSON.stringify({
                    ...getReturnId(),
                    attachment,
                }),
            ],
            { type: "application/json" },
        );

        formData.append("attachmentMetadata", attachmentMetadata);

        try {
            await attachmentsService.uploadAttachment(formData);
            fetchUpdatedReturnDocument(type === "SYSTEM_DEFINED");
        } catch (error) {
            console.error("Error uploading files:", error);
        }
    };

    const handleUserDefinedFilePick = (
        event: ChangeEvent<HTMLInputElement>,
    ) => {
        const selectedFiles = event.target.files;

        if (selectedFiles && selectedFiles.length > 0) {
            const file = selectedFiles[0];
            if (!validateAttachmentFile(file)) return;

            uploadAttachment(file, null, "USER_DEFINED");
        }
    };

    const handleUserDefinedFileDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        const droppedFiles = event.dataTransfer.files;

        if (droppedFiles && droppedFiles.length > 0) {
            const file = droppedFiles[0];
            if (!validateAttachmentFile(file)) return;

            uploadAttachment(file, null, "USER_DEFINED");
        }
    };

    const handleSystemDefinedFilePick = (
        event: ChangeEvent<HTMLInputElement>,
        attachmentId: number,
    ) => {
        const selectedFiles = event.target.files;

        if (selectedFiles && selectedFiles.length > 0) {
            const file = selectedFiles[0];
            if (!validateAttachmentFile(file)) return;

            uploadAttachment(file, attachmentId, "SYSTEM_DEFINED");
        }
    };

    const onViewAttachment = async (fileId: string, fileExt: string) => {
        const attachment = await attachmentsService.getAttachment(
            fileId,
            fileExt,
        );

        const blob = new Blob([await attachment.blob()], {
            type: `application/${fileExt}`,
        });

        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
    };

    const handleDeleteUserDefinedAttachment = async (id: string) => {
        const deleteAttachmentPayload = {
            ...getReturnId(),
            attachmentId: id,
        };

        try {
            await attachmentsService.deleteAttachment(deleteAttachmentPayload);
            fetchUpdatedReturnDocument();
        } catch (error) {
            console.error("Error uploading files:", error);
        }
    };

    const handleDeleteSystemDefinedAttachment = async (id: number) => {
        let toBeDeletedSystemDefinedAttachment = {
            ...returnDocument?.attachments?.find(
                (attachment: AttachmentMetadata) => attachment.id === id,
            ),
        };

        toBeDeletedSystemDefinedAttachment.customFileName = "";
        toBeDeletedSystemDefinedAttachment.fileIdentifier = "";

        const attachmentMetadata = new Blob(
            [
                JSON.stringify({
                    ...getReturnId(),
                    attachment: toBeDeletedSystemDefinedAttachment,
                }),
            ],
            {
                type: "application/json",
            },
        );

        const formData = new FormData();

        formData.append("attachmentMetadata", attachmentMetadata);

        try {
            await attachmentsService.uploadAttachment(formData);
            fetchUpdatedReturnDocument(true);
        } catch (error) {
            console.error("Error deleting files:", error);
        }
    };

    const fetchUpdatedReturnDocument = async (
        systemDefinedAttachmentChanged: boolean = false,
    ) => {
        const returnId = getReturnId();

        const returnDocumentAndValidationResponse: ReturnDocumentAndValidationsDTO =
            await returnDocumentService.getReturnDocument(returnId);
        const fetchedDocument: ReturnDocument =
            returnDocumentAndValidationResponse.returnDocument;
        setReturnDocument(fetchedDocument);
    };

    const handleDescriptionChange = async (attachmentId, newDescription) => {
        const attachment = {
            ...returnDocument?.attachments?.find(
                (attachment: AttachmentMetadata) =>
                    attachment.id === attachmentId,
            ),
        };

        attachment.description = newDescription;

        const attachmentMetadata = new Blob(
            [
                JSON.stringify({
                    ...getReturnId(),
                    attachment,
                }),
            ],
            { type: "application/json" },
        );

        const formData = new FormData();
        formData.append("attachmentMetadata", attachmentMetadata);

        try {
            await attachmentsService.uploadAttachment(formData);
            fetchUpdatedReturnDocument();
        } catch (error) {
            console.error("Error updating description:", error);
        }
    };

    const columns = [
        {
            field: "view",
            headerName: "View",
            width: 50,
            maxWidth: 50,
            renderCell: (params) => (
                <>
                    {params.row.view.fileId && params.row.view.fileExt ? (
                        <VisibilityIcon
                            data-testid="view-attachment-icon"
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                                onViewAttachment(
                                    params.row.view.fileId,
                                    params.row.view.fileExt,
                                )
                            }
                        />
                    ) : (
                        <VisibilityOffIcon />
                    )}
                </>
            ),
        },
        {
            field: "type",
            headerName: "Type",
            width: 50,
            maxWidth: 50,
            renderCell: (params) =>
                params.row.type === "SYSTEM_DEFINED" ? (
                    <AttachEmailIcon />
                ) : (
                    <AttachFileIcon />
                ),
        },
        {
            field: "fileName",
            headerName: "File Name",
            width: 160,
            maxWidth: 240,
            flex: 1,
        },
        {
            field: "description",
            headerName: "Description",
            width: 240,
            maxWidht: 300,
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
                <GridEditInputCell
                    {...params}
                    inputProps={{
                        maxLength: 25,
                    }}
                />
            ),
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 100,
            maxWidth: 200,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.view.fileId && (
                            <Delete
                                sx={{ cursor: "pointer", marginRight: "12px" }}
                                onClick={() => {
                                    if (params.row.type === "USER_DEFINED") {
                                        handleDeleteUserDefinedAttachment(
                                            params.row.id,
                                        );
                                    } else {
                                        handleDeleteSystemDefinedAttachment(
                                            params.row.id,
                                        );
                                    }
                                }}
                            />
                        )}

                        {params.row.type === "SYSTEM_DEFINED" &&
                            !params.row.view.fileId && (
                                <>
                                    <label
                                        htmlFor={`systemDefinedFileInput-${params.row.id}`}
                                    >
                                        <CloudUploadIcon
                                            sx={{ cursor: "pointer" }}
                                        />
                                    </label>
                                    <input
                                        type="file"
                                        id={`systemDefinedFileInput-${params.row.id}`}
                                        style={{ display: "none" }}
                                        onChange={(event) => {
                                            // Pass the correct attachment ID to the handler
                                            handleSystemDefinedFilePick(
                                                event,
                                                params.row.id,
                                            );
                                        }}
                                    />
                                </>
                            )}
                    </>
                );
            },
        },
    ];

    const rows: DataGridRow[] =
        returnDocument?.attachments?.map((attachment: AttachmentMetadata) => ({
            id: attachment.id!!,
            view: {
                fileId: attachment?.fileIdentifier,
                fileExt: attachment?.fileName?.split(".").pop()?.toLowerCase(),
            },
            fileName: attachment?.customFileName,
            type: attachment?.type,
            description: `${attachment?.description} ${
                attachment?.required ? "*" : ""
            }`,
        })) ?? [];

    const isCellEditable = (params: GridCellParams): boolean => {
        return params.row["type"] !== "SYSTEM_DEFINED";
    };

    const processRowUpdate = (newRow: DataGridRow, oldRow: DataGridRow) => {
        const newDescription = newRow["description"];
        handleDescriptionChange(newRow["id"], newDescription);
        return {
            ...newRow,
        };
    };

    return (
        <div
            className="attachments-container"
            data-testid="attachments-container"
        >
            <div className="attachments-list">
                {rows && rows.length > 0 ? (
                    <DataGridPremium
                        columns={columns}
                        rows={rows}
                        isCellEditable={isCellEditable}
                        processRowUpdate={processRowUpdate}
                    />
                ) : (
                    <div className="no-attachments">No attachments found.</div>
                )}
            </div>

            <div className="file-upload">
                <FileUploadContainer>
                    <label htmlFor="userDefinedFileInput">
                        <FileUploadRectangle
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleUserDefinedFileDrop}
                        >
                            <AttachFileIcon fontSize="large" color="primary" />
                        </FileUploadRectangle>
                    </label>
                    <input
                        type="file"
                        id="userDefinedFileInput"
                        data-testid="userDefinedFileInput"
                        style={{ display: "none" }}
                        onChange={handleUserDefinedFilePick}
                    />

                    <p>Drag & drop file here</p>
                </FileUploadContainer>
            </div>

            <div className="icons-container">{infoIcons}</div>
        </div>
    );
};

export default Attachments;
