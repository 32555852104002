import MailIcon from "@mui/icons-material/Mail";
import { Button, Modal, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { EFileEmailService } from "src/services";
import { FixMeLater, ReturnDocument, ValidationResponse } from "src/types";
import { EmailKey, EmailMessage } from "src/types";
import "./EFileEmailModal.scss";

interface EFileEmailModalProps {
    returnDocument: ReturnDocument;
    open: boolean;
    validationResponse: ValidationResponse | null;
}

const EFileEmailModal: React.FC<EFileEmailModalProps> = ({
    returnDocument,
    open,
    validationResponse,
}) => {
    const [tab, setTab] = React.useState(0);
    const [emailInformation, setEmailInformation] =
        React.useState<EmailMessage>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<FixMeLater>(null);

    const eFileEmailService = EFileEmailService.getInstance();
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("info"); // using info as default
    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const getEmailKey = (returnDocument: ReturnDocument): EmailKey => {
        if (returnDocument.id) {
            return {
                companyId: returnDocument.id.companyId,
                folderId: returnDocument.id.folderId,
                productId: returnDocument.id.productId,
                taxYearId: returnDocument.id.taxYearId,
                moduleId: returnDocument.id.moduleId,
                returnId: returnDocument.id.returnId,
                retalFolderId: returnDocument.id.retalFolderId,
            };
        } else {
            console.error("Return Document ID is not defined.");
            return {
                companyId: 0,
                folderId: 0,
                productId: 0,
                taxYearId: 0,
                moduleId: 0,
                returnId: 0,
                retalFolderId: 0,
            };
        }
    };

    useEffect(() => {
        if (open && returnDocument && !validationResponse?.failures?.length) {
            const fetchInfo = async () => {
                try {
                    setIsLoading(true);
                    setEmailInformation(
                        await eFileEmailService.getEmailMessageInformation(
                            getEmailKey(returnDocument),
                        ),
                    );
                } catch (error) {
                    console.error("Error updating schedule:", error);
                    setError(error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchInfo();
        }
    }, [open]);

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
        id: string;
        controls: string;
    }

    const sendEmail = async () => {
        try {
            setIsLoading(true);
            await eFileEmailService.sendEfileEmail(getEmailKey(returnDocument));
            handleSnackbar("Message Sent!", "success");
        } catch (error) {
            console.error("Error sending Message:", error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const toEmailComponent = (commaSeparatedEmails) => {
        return commaSeparatedEmails?.split(";").map((email, index) => (
            <span className="efile-email" key={`efile-email${index}`}>
                {email}
            </span>
        ));
    };

    const loadJoinedAttachments = () => {
        return "📎 " + emailInformation?.attachments.join("\n📎 ");
    };

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, id, controls } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={controls}
                aria-labelledby={id}
            >
                {value === index && <>{children}</>}
            </div>
        );
    }
    return (
        <div className="EFileModalContainer">
            {error ? (
                <ErrorMessage
                    error={`Error fetching the pdf: ${error.toString()}`}
                />
            ) : !error && isLoading ? (
                <Loader />
            ) : (
                <div className="EFileModalContent">
                    {!validationResponse?.failures?.length ? (
                        <>
                            <Tabs
                                className="efile-tab-container"
                                orientation="vertical"
                                value={tab}
                                onChange={handleChange}
                            >
                                <Tab
                                    label="Instructions"
                                    id="efile-tab-instructions"
                                    aria-controls="efile-tabpanel-instructions"
                                />
                                <Tab
                                    label="Review Email"
                                    id="efile-tab-review"
                                    aria-controls="efile-tabpanel-review"
                                />
                            </Tabs>
                            <div className="TabPanelContent">
                                <TabPanel
                                    value={tab}
                                    index={0}
                                    id="efile-tab-instructions"
                                    controls="efile-tabpanel-instructions"
                                >
                                    <span className="efile-tabpanel-title">
                                        CA Email
                                    </span>
                                    <p>
                                        {"This will generate a PDF of "}
                                        <b>{returnDocument?.displayName}</b>
                                        {
                                            ". This file and any applicable attachments selected during the process will then be emailed to the state on your behalf."
                                        }
                                    </p>
                                    <p>
                                        {
                                            "To add additional attachments that serve as supporting documentation for this form, close this form and add the necessary attachments first. Then you may email this form."
                                        }
                                    </p>
                                    <p>
                                        {
                                            "Confirmation Emails will be sent to the following:"
                                        }
                                    </p>
                                    <p>
                                        <a
                                            href={`mailto:${emailInformation?.toEmailAddress}`}
                                        >
                                            {emailInformation?.toEmailAddress}
                                        </a>
                                    </p>
                                    <p>
                                        {
                                            "To add additional email address, add them via the options menu."
                                        }
                                    </p>
                                </TabPanel>
                                <TabPanel
                                    value={tab}
                                    index={1}
                                    id="efile-tab-review"
                                    controls="efile-tabpanel-review"
                                >
                                    <span className="efile-tabpanel-title">
                                        Review and Submit Email
                                    </span>
                                    <div className="efile-email-property">
                                        <div className="efile-email-property-name">
                                            To:
                                        </div>
                                        <div className="efile-email-property-value">
                                            {toEmailComponent(
                                                emailInformation?.toEmailAddress,
                                            )}
                                        </div>
                                    </div>
                                    <div className="efile-email-property">
                                        <div className="efile-email-property-name">
                                            Cc:
                                        </div>
                                        <div className="efile-email-property-value">
                                            {emailInformation?.ccEmailAddress !==
                                            "" ? (
                                                toEmailComponent(
                                                    emailInformation?.ccEmailAddress,
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                    <div className="efile-email-property">
                                        <div className="efile-email-property-name">
                                            Subject
                                        </div>
                                        <div className="efile-email-property-value">
                                            <span key={"efile-subject"}>
                                                {emailInformation?.subject}
                                            </span>
                                        </div>
                                    </div>
                                    <br />
                                    <span className="efile-tabpanel-title">
                                        Attachments
                                    </span>
                                    <textarea
                                        className="efile-tabpanel-attachments"
                                        readOnly
                                        defaultValue={loadJoinedAttachments()}
                                    ></textarea>
                                    <span className="efile-tabpanel-title">
                                        Body
                                    </span>
                                    <textarea
                                        className="efile-tabpanel-body"
                                        readOnly
                                        defaultValue={emailInformation?.body}
                                    ></textarea>
                                </TabPanel>
                                <Button
                                    startIcon={<MailIcon />}
                                    className="efile-send-email-button"
                                    onClick={sendEmail}
                                >
                                    Email
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="efile-tabpanel-errors">
                            <span className="efile-tabpanel-title">
                                The following requirements have not been met.
                            </span>
                            <ul>
                                {validationResponse?.failures?.map(
                                    (response, index) => (
                                        <li key={`attachment${index}`}>
                                            {response.message}
                                        </li>
                                    ),
                                )}
                            </ul>
                        </div>
                    )}
                    <CustomSnackbar
                        open={snackbarOpen}
                        setOpen={setSnackbarOpen}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                </div>
            )}
        </div>
    );
};
export default EFileEmailModal;
