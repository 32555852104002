import { GridRowModel } from "@mui/x-data-grid";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AllocatorJob, AllocatorJobsPayload, AllocatorJobStatus, AllocatorJobsState, AllocatorReportTab, CorrectedAddresses, AllocatorReportExportOption, AllocatorReportFilterState, AllocatorReportColumnsWidth, AllocatorReportsPageSize } from "src/types";

interface AllocatorState {
    value: {
        jobs: AllocatorJobsState;
        correctedAddresses: CorrectedAddresses;
        toogleFetchReport: boolean;
        reportTabs: AllocatorReportTab[];
        reportTabsLoading: boolean;
        selectedReportTab: AllocatorReportTab | null;
        reportsFilterState: AllocatorReportFilterState;
        reportsPageSize: AllocatorReportsPageSize;
        reportColumnsWidth: AllocatorReportColumnsWidth;
        reportColumns: string[];
        downloadOptions: AllocatorReportExportOption[];
        exportOptions: AllocatorReportExportOption[];
        stateAbbr: string | null;
    }
}

const initialState: AllocatorState = {
    value: {
        jobs: {
            jobList: [],
            selectedJob: null,
            selectedJobStatus: null,
            selectedFileName: null,
            page: 0,
            toogleFetchJobs: false,
            filterState: null,
            isJobSearchActive: false,
        },
        correctedAddresses: {},
        toogleFetchReport: false,
        reportTabs: [],
        reportTabsLoading: false,
        selectedReportTab: null,
        reportsFilterState: {},
        reportsPageSize: {},
        reportColumnsWidth: {},
        reportColumns: [],
        downloadOptions: [],
        exportOptions: [],
        stateAbbr: null,
    },
};

export const AllocatorSlice = createSlice({
    name: "Allocator",
    initialState,
    reducers: {
        setJobList: (state, action: PayloadAction<AllocatorJob[]>) => {
            state.value.jobs.jobList = action.payload;
        },
        setUpdatedJob: (state, action: PayloadAction<AllocatorJob>) => {
            state.value.jobs.jobList = state.value.jobs.jobList?.map((job) => {
                if (job.id === action.payload?.id) {
                    if (job?.progress && action.payload?.progress && job?.progress > action.payload?.progress) {
                        return job;
                    } else return { ...job, ...action.payload }
                } else return job;
            })
        },
        setJobsPage: (state, action: PayloadAction<number>) => {
            state.value.jobs.page = action.payload;
        },
        setToogleFetchJobs: (state) => {
            state.value.jobs.toogleFetchJobs =
                !state.value.jobs.toogleFetchJobs;
        },
        setJobsFilterState: (state, action: PayloadAction<AllocatorJobsPayload>) => {
            state.value.jobs.filterState = action.payload;
        },
        setIsJobSearchActive: (state , action: PayloadAction<boolean>) => {
            state.value.jobs.isJobSearchActive = action.payload;
        },
        setSelectedJob: (state, action: PayloadAction<AllocatorJob>) => {
            state.value.jobs.selectedJob = action.payload;
        },
        setUpdateSelectedJob: (state, action: PayloadAction<AllocatorJob>) => {
            state.value.jobs.selectedJob =
                state.value.jobs.selectedJob?.id === action.payload?.id
                    ? {
                          ...state.value.jobs.selectedJob,
                          ...action.payload,
                      }
                    : state.value.jobs.selectedJob;
        },
        setSelectedJobStatus: (state, action: PayloadAction<AllocatorJobStatus>) => {
            state.value.jobs.selectedJobStatus = action.payload;
        },
        setSelectedFileName: (state, action: PayloadAction<string>) => {
            state.value.jobs.selectedFileName = action.payload;
        },
        setCorrectedAddresses: (state, action: PayloadAction<GridRowModel>) => {
            state.value.correctedAddresses[action.payload.key] = {
                ...state.value.correctedAddresses[action.payload.key],
                ...action.payload,
            };
        },
        setRemoveCorrectedAddress: (state, action: PayloadAction<string>) => {
            delete state.value.correctedAddresses[action.payload];
        },
        setEmptyCorrectedAddresses: (state) => {
            state.value.correctedAddresses = {};
        },
        setToogleFetchReport: (state) => {
            state.value.toogleFetchReport = !state.value.toogleFetchReport;
        },
        setReportTabs: (state, action: PayloadAction<AllocatorReportTab[]>) => {
            state.value.reportTabs = action.payload;
        },
        setReportTabsLoading: (state, action: PayloadAction<boolean>) => {
            state.value.reportTabsLoading = action.payload;
        },
        setSelectedReportTab: (state, action: PayloadAction<AllocatorReportTab>) => {
            state.value.selectedReportTab = action.payload;
        },
        setReportsFilterState: (state, action: PayloadAction<AllocatorReportFilterState>) => {
            state.value.reportsFilterState = {
                ...state.value.reportsFilterState,
                ...action.payload,
            };
        },
        setEmptyReportsFilterState: (state) => {
            state.value.reportsFilterState = {};
        },
        setReportColumnsWidth: (state, action: PayloadAction<AllocatorReportColumnsWidth>) => {
            state.value.reportColumnsWidth[Object.keys(action.payload)?.[0]] = {
                ...state.value.reportColumnsWidth[Object.keys(action.payload)?.[0]],
                ...action.payload[Object.keys(action.payload)?.[0]],
            };
        },
        setReportColumns: (state, action: PayloadAction<string[]>) => {
            state.value.reportColumns = action.payload;
        },
        setReportsPageSize: (state, action: PayloadAction<AllocatorReportsPageSize>) => {
            state.value.reportsPageSize = {
                ...state.value.reportsPageSize,
                ...action.payload,
            };
        },
        setDownloadOptions: (state, action: PayloadAction<AllocatorReportExportOption[]>) => {
            state.value.downloadOptions = action.payload;
        },
        setExportOptions: (state, action: PayloadAction<AllocatorReportExportOption[]>) => {
            state.value.exportOptions = action.payload;
        },
        setStateAbbr: (state, action: PayloadAction<string>) => {
            state.value.stateAbbr = action.payload;
        },
    },
});

export const {
    setJobList,
    setUpdatedJob,
    setJobsPage,
    setToogleFetchJobs,
    setJobsFilterState,
    setIsJobSearchActive,
    setSelectedJob,
    setUpdateSelectedJob,
    setSelectedJobStatus,
    setSelectedFileName,
    setCorrectedAddresses,
    setRemoveCorrectedAddress,
    setEmptyCorrectedAddresses,
    setToogleFetchReport,
    setReportTabs,
    setReportTabsLoading,
    setSelectedReportTab,
    setReportsFilterState,
    setEmptyReportsFilterState,
    setReportsPageSize,
    setReportColumnsWidth,
    setReportColumns,
    setDownloadOptions,
    setExportOptions,
    setStateAbbr,
} = AllocatorSlice.actions;
export default AllocatorSlice.reducer;
