import React from "react";
import {
    TextField,
    NumberField,
    ComboBoxField,
    CheckField,
    LabelField,
    SignatureField,
} from "src/components/PdfInputFields/PdfInputFields";
import {
    CheckField as CheckFieldType,
    Company,
    FieldTemplatesContainer,
    NoteFieldType,
    NumField as NumFieldType,
    Product,
    QueryParams,
    ReturnDocument,
    ReturnNode,
    ReturnPage,
    TextField as TextFieldType,
} from "src/types";
import { generateUniqueId } from "src/utils";

// Function to get overlay fields based on field templates and index
export const getOverlayFields = (
    fieldTemplates: FieldTemplatesContainer[] | undefined,
    returnPage: ReturnPage,
    scale: number,
    returnDocument: ReturnDocument | undefined,
    updateReturnDocument,
    isPaymentRequest: boolean = false,
    company: Company,
    product: Product,
    returnNode: ReturnNode,
    returnInput: QueryParams,
    onClickHyperlinkCallback: (hyperlink: string) => void,
) => {

    if (!fieldTemplates || !returnDocument) return [];

    let index = returnPage.attributes.pageOrder;

    // Check if the index is within valid bounds
    if (
        index < 0 ||
        index >= fieldTemplates?.length ||
        index >= (returnDocument?.pages?.length ?? 0)
    ) {
        return [];
    }

    const returnDocumentPage = returnDocument.pages?.find(
        (x) => x.pageOrder === index,
    );

    const textFieldValues = returnDocumentPage?.["textFields"];
    const numberFieldValues = returnDocumentPage?.["numFields"];
    const checkFieldValues = returnDocumentPage?.["checkFields"];

    const isLocked = returnDocument?.isLocked;

    // Map check field templates to CheckField components
    const checkFieldTemplates = fieldTemplates[index]["checkFieldTemplates"]
        ?.filter((field) => field.isVisible)
        .map((field) => {
            const sanitizedValue = getSanitizedValue<CheckFieldType>(
                checkFieldValues!,
                field,
            );
            const uniqueCheckFieldId = generateUniqueId(
                product,
                company,
                returnNode,
                returnPage,
                NoteFieldType.CHECKBOX,
                field.id,
            );
            return (
                <CheckField
                    templateField={field}
                    key={`${uniqueCheckFieldId}-key`}
                    scale={scale}
                    value={sanitizedValue}
                    updateReturnDocument={updateReturnDocument}
                    isLocked={isLocked}
                    returnPage={returnPage}
                    data-testid = {`${uniqueCheckFieldId}-testid`}
                    id={uniqueCheckFieldId}
                />
            );
        });

    // Map comboBox field templates to ComboBoxField components
    const comboBoxFieldTemplates = fieldTemplates[index][
        "comboBoxFieldTemplates"
    ]
        ?.filter((field) => field.isVisible)
        .map((field) => {
            const sanitizedValue = getSanitizedValue<TextFieldType>(
                textFieldValues!,
                field,
            );
            const uniqueComboBoxFieldId = generateUniqueId(
                product,
                company,
                returnNode,
                returnPage,
                NoteFieldType.COMBO_BOX,
                field.id,
            );
            return (
                <ComboBoxField
                    templateField={field}
                    key={`${uniqueComboBoxFieldId}-key`}
                    scale={scale}
                    value={sanitizedValue}
                    updateReturnDocument={updateReturnDocument}
                    isLocked={isLocked}
                    returnPage={returnPage}
                    data-testid = {`${uniqueComboBoxFieldId}-testid`}
                    id={uniqueComboBoxFieldId}
                />
            );
        });

    // Map label field templates to LabelField components
    const labelFieldTemplates = fieldTemplates[index]["labelFieldTemplates"]
        ?.filter((field) => field.isVisible && field.textValue)
        .map((field) => {
            const uniqueLabelFieldId = generateUniqueId(
                product,
                company,
                returnNode,
                returnPage,
                NoteFieldType.LABEL,
                field.id,
            );
            return (
                <LabelField
                    field={field}
                    key={`${uniqueLabelFieldId}-key`}
                    scale={scale}
                    returnPage={returnPage}
                    data-testid = {`${uniqueLabelFieldId}-testid`}
                    id={uniqueLabelFieldId}
                />
            );
        });

    // Map signature field templates to SignatureField components
    const signatureFieldTemplates = fieldTemplates[index]["signatureFieldTemplates"]
        ?.filter((field) => field.isVisible && field.isEnabled)
        .map((field) => {
            return (
                <SignatureField
                    field={field}
                    key={field?.fieldType + field?.id + index}
                    scale={scale}
                    returnInput={returnInput}
                    isLocked={isLocked}
                />
            );
        });

    // Map number field templates to NumberField components
    const numberFieldTemplates = fieldTemplates[index]["numberFieldTemplates"]
        ?.filter((templateField) => templateField.isVisible)
        .map((templateField) => {
            const sanitizedValue = getSanitizedValue<NumFieldType>(
                numberFieldValues!,
                templateField,
            );
            const uniqueNumberFieldId = generateUniqueId(
                product,
                company,
                returnNode,
                returnPage,
                NoteFieldType.NUMBER,
                templateField.id,
            );
            return (
                <NumberField
                    templateField={templateField}
                    key={`${uniqueNumberFieldId}-key`}
                    scale={scale}
                    value={sanitizedValue}
                    updateReturnDocument={updateReturnDocument}
                    isLocked={isLocked}
                    isPaymentRequest={isPaymentRequest}
                    returnPage={returnPage}
                    returnDocument={returnDocument}
                    data-testid = {`${uniqueNumberFieldId}-testid`}
                    id={uniqueNumberFieldId}
                    onClickHyperlinkCallback={onClickHyperlinkCallback}
                />
            );
        });

    // Map text field templates to TextField components
    const textFieldTemplates = fieldTemplates[index]["textFieldTemplates"]
        ?.filter((templateField) => templateField.isVisible)
        .map((templateField) => {
            const sanitizedValue = getSanitizedValue<TextFieldType>(
                textFieldValues!,
                templateField,
            );
            const uniqueTextFieldId = generateUniqueId(
                product,
                company,
                returnNode,
                returnPage,
                NoteFieldType.TEXT,
                templateField.id,
            );
            return (
                <TextField
                    templateField={templateField}
                    key={`${uniqueTextFieldId}-key`}
                    scale={scale}
                    value={sanitizedValue}
                    updateReturnDocument={updateReturnDocument}
                    isLocked={isLocked}
                    isPaymentRequest={isPaymentRequest}
                    returnPage={returnPage}
                    returnDocument={returnDocument}
                    id={uniqueTextFieldId}
                    onClickHyperlinkCallback={onClickHyperlinkCallback}
                />
            );
        });

    // Concatenate all field components
    return [
        ...(checkFieldTemplates || []),
        ...(comboBoxFieldTemplates || []),
        ...(labelFieldTemplates || []),
        ...(signatureFieldTemplates || []),
        ...(numberFieldTemplates || []),
        ...(textFieldTemplates || []),
    ];
};

type FieldType = CheckFieldType | TextFieldType | NumFieldType;

export function getSanitizedValue<T extends FieldType>(
    fieldValues: T[],
    field,
): { value: string | number | boolean; isOverride: boolean; documentField: T } {
    const fieldValue = fieldValues?.find((data) => data?.id == field?.id)!;
    let valueToUse: string | number | boolean;
    let isOverride = false;

    // Check if overrideValue exists and use it, otherwise use the default value
    if (
        fieldValue?.overrideValue !== undefined &&
        fieldValue?.overrideValue !== null &&
        fieldValue?.overrideValue !== ""
    ) {
        valueToUse = fieldValue?.overrideValue;
        isOverride = true;
    } else {
        valueToUse = fieldValue?.value ?? "";
    }
    return { value: valueToUse, isOverride, documentField: fieldValue };
}
