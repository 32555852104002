// General.tsx
import { Button, Checkbox, FormControlLabel, Radio } from "@mui/material";
import { DateRange, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import React, { ChangeEvent, FC } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FixMeLater } from "src/types";
import "./General.scss";

const ACCEPTED_FILE_TYPE = "text/csv";

interface GeneralProps {
    isAppend: boolean;
    setIsAppend: (value: boolean) => void;
    file: File | null;
    setFile: (file: File) => void;
    handleSnackbar: (message: string, severity: string) => void;
}

const General: FC<GeneralProps> = ({ isAppend, setIsAppend, file, setFile, handleSnackbar }) => {
    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const paymentRequestExport = useAppSelector(
        (state) => state?.[product?.productName]?.value?.paymentRequestExport
    );

    const dispatch = useAppDispatch();

    const handlePaymentTypeChange = (name: string) => {
        const updatedPaymentTypes =
            paymentRequestExport?.payload?.paymentTypes?.includes(name)
                ? paymentRequestExport?.payload?.paymentTypes?.filter(
                      (type) => type !== name
                  )
                : [
                      ...(paymentRequestExport?.payload?.paymentTypes || []),
                      name,
                  ];

        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    paymentTypes: updatedPaymentTypes,
                },
            })
        );
    };

    const handleDateChange = (date?: DateRange<Dayjs>, dateType?: string) => {
        const startDate = dayjs(date?.[0]).format("YYYY-MM-DD") || "";
        const endDate = dayjs(date?.[1]).format("YYYY-MM-DD") || "";
        
        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport({
                ...paymentRequestExport,
                payload: {
                    ...paymentRequestExport?.payload,
                    maxApproveDate: dateType === "minApproveDate" ? endDate : "",
                    maxDueDate: dateType === "minDueDate" ? endDate : "",
                    minApproveDate: dateType === "minApproveDate" ? startDate : "",
                    minDueDate: dateType === "minDueDate" ? startDate : "",
                    dueDateRange: dateType === "minDueDate",
                },
            })
        );
    };

    const validateUploadedFile = (file: File) => {
        if (!file) {
            handleSnackbar("Error uploading file.", "error");
            return false;
        }
    
        if (file?.type !== ACCEPTED_FILE_TYPE) {
            handleSnackbar("Invalid file type. Please select a .csv file.", "error");
            return false;
        }
    
        return true;
    }

    const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;

        if (selectedFiles && selectedFiles.length > 0) {
            const file = selectedFiles[0];
            if (!validateUploadedFile(file)) return;

            setFile(file);
            handleSnackbar(`You uploaded the file: ${file?.name}`, "success");
        }
    }

    return (
        <div className="general-container">
            <div className="header">Export Options</div>
            <hr />
            <div>
                <span className="subheader">Payment Type:</span>
                {["Check", "ACH Credit", "ACH Debit", "Credit Card"].map(
                    (paymentType) => (
                        <FormControlLabel
                            key={paymentType}
                            control={
                                <Checkbox
                                    onChange={() =>
                                        handlePaymentTypeChange(paymentType)
                                    }
                                    checked={paymentRequestExport?.payload.paymentTypes?.includes(
                                        paymentType
                                    )}
                                />
                            }
                            label={paymentType}
                        />
                    )
                )}
            </div>

            <FormControlLabel
                control={
                    <Checkbox
                        onChange={() => setIsAppend(!isAppend)}
                        checked={isAppend}
                    />
                }
                label="Append exported record to an existing file."
            />

            <hr />

            <div className="header">File Options</div>
            <div className="file-uploader">
                <Button variant="contained" component="label" disabled={!isAppend}>
                    Upload File
                    <input type="file" hidden accept={ACCEPTED_FILE_TYPE} onChange={handleUpload}/>
                </Button>
                <div className="file-name">{file?.name}</div>
            </div>

            <div>
                <div>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={!paymentRequestExport?.payload?.minDueDate && !paymentRequestExport?.payload?.minApproveDate}
                                onChange={() => handleDateChange()}
                            />
                        }
                        label="All Dates"
                    />
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={!!paymentRequestExport?.payload?.minDueDate && paymentRequestExport?.payload?.dueDateRange}
                                onChange={() => handleDateChange(
                                    [
                                        dayjs(
                                            paymentRequestExport?.payload?.minDueDate ||
                                                undefined
                                        ),
                                        dayjs(
                                            paymentRequestExport?.payload?.maxDueDate ||
                                                undefined
                                        ),
                                    ],
                                    "minDueDate"
                                )}
                            />
                        }
                        label="Due Date Range"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            value={[
                                dayjs(
                                    paymentRequestExport?.payload?.minDueDate || new Date()
                                ),
                                dayjs(
                                    paymentRequestExport?.payload?.maxDueDate || new Date()
                                ),
                            ]}
                            disabled={!paymentRequestExport?.payload?.minDueDate || !paymentRequestExport?.payload?.dueDateRange}
                            localeText={{ start: "From", end: "To" }}
                            onChange={(date) =>
                                handleDateChange(date, "minDueDate")
                            }
                        />
                    </LocalizationProvider>
                </div>

                <div>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={!!paymentRequestExport?.payload?.minApproveDate && !paymentRequestExport?.payload?.dueDateRange}
                                onChange={() => handleDateChange(
                                    [
                                        dayjs(
                                            paymentRequestExport?.payload
                                                ?.minApproveDate || undefined
                                        ),
                                        dayjs(
                                            paymentRequestExport?.payload
                                                ?.maxApproveDate || undefined
                                        ),
                                    ], 
                                    "minApproveDate"
                                )}
                            />
                        }
                        label="Approval Date Range"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            value={[
                                dayjs(
                                    paymentRequestExport?.payload
                                        ?.minApproveDate || new Date()
                                ),
                                dayjs(
                                    paymentRequestExport?.payload
                                        ?.maxApproveDate || new Date()
                                ),
                            ]}
                            localeText={{ start: "From", end: "To" }}
                            disabled={!paymentRequestExport?.payload?.minApproveDate || paymentRequestExport?.payload?.dueDateRange}
                            onChange={(date) =>
                                handleDateChange(date, "minApproveDate")
                            }
                        />
                    </LocalizationProvider>
                </div>
            </div>
        </div>
    );
};

export default General;
