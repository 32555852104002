import styled from "styled-components";
import colors from "src/styles/colors.scss";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { DataGridProps } from "@mui/x-data-grid";

export const StyledCustomColumnHeaderIcons = styled.div`
  display: none;
  align-items: center;
  margin-left: 10px;
  position: absolute;
  background-color: #e8e8e8;
  right: 3px;
  top: 3px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 2px;
`;

export const StyledCustomColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 100%;
  position: relative;

  &:hover {
    cursor: s-resize;
  }

  span {
    margin-right: auto;
    word-break: break-word;
    white-space: break-spaces;
    font-weight: bold;
  }

`;

export const StyledDataGrid = styled(DataGridPremium)<DataGridProps>`

  &.MuiDataGrid-root {
    border: 1px solid #ddd;
    .MuiDataGrid-columnHeaders{
      background-color: #beb9b9;
      max-height: unset !important;
      line-height: normal !important;
    }
  }

  & .MuiDataGrid-cell {
    padding: 8px;
    display: flex;
    align-items: center;
    &:hover {
      cursor: cell;
    }
  }

  & .MuiInputBase-input {
    color: ${colors.premiumTaxDataTableEditableTextColor};
    font-size: 14px;
  }

  & .MuiDataGrid-cell--editable {
    color: ${colors.premiumTaxDataTableEditableTextColor};
  }

  & .MuiDataGrid-row:nth-child(odd):not(.Mui-selected) {
    background-color: #f2f2f2;
  }

  & .MuiDataGrid-row:nth-child(even):not(.Mui-selected) {
    background-color: #fff;
  }

  & .MuiDataGrid-row:hover {
    background-color: #f2f2f2;
  }

  & .MuiDataGrid-columnHeader {
    background-color: #beb9b9;
    height: inherit !important;
    border-left: 1px solid #e0e0e0;
    padding: 0;

    .MuiDataGrid-menuIcon {
      width: 0;
      margin-right: 0;
    }

    &:hover .MuiDataGrid-menuIcon {
      width: 0;
      margin-right: 0;
    }
    .MuiDataGrid-menuOpen {
        width: 0;
    }

    &:hover {
      ${StyledCustomColumnHeaderIcons} {
        display: flex;
        z-index: 1;
      }
    }
  }

  & .MuiDataGrid-columnHeaderTitleContainer {
    width: 100%;
  }

  & .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 100%;
    height: 100%;
  }

  & .MuiDataGrid-columnHeaderTitle {
    background-color: #beb9b9;
    color: ${colors.premiumTaxDataTableColumnHeaderTextColor};
    font-weight: bold;
    color: rebeccapurple;
    white-space: break-spaces;
    word-break: break-word;
    text-overflow: initial;
    overflow: visible;
  }

  & .MuiSvgIcon-root {
    color: ${colors.premiumTaxDataTableIconColor};
  }

  & .MuiToolbar-root {
    color: ${colors.premiumTaxDataTableTextColor};
  }

  & .MuiDataGrid-overlayWrapper {
    height: auto !important
  }

  & .MuiDataGrid-overlayWrapperInner {
    height: auto !important
  }

  & .first-column-cell {
    background-color: #beb9b9;
    color: ${colors.black};
  }

  & .overrided-column-cell{
    color: red
  }

  & .MuiDataGrid-cellCheckbox{
    background-color: #beb9b9;
  }

  & .MuiDataGrid-columnHeaderCheckbox{
    background-color: #beb9b9;
  }

  & .MuiDataGrid-filler {
    max-height: 50px;
  }

  & .MuiDataGrid-scrollbar--horizontal {
    background-color: #fff;
  }

  & .MuiDataGrid-scrollbar--vertical {
    background-color: #fff;
  }
   & .align-right {
        justify-content: flex-end;
        text-align: right;
    }
`;

export const StyledCustomNoData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px;
    width: 100%;
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
`;