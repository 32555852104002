// Types based on stratus-api/src/main/resources/graphql/return_template.graphqls

export interface ReturnTemplate {
    id: string
    displayName: string
    isAnnualStatementChecklist: boolean
    printForMonth?: number
    isOceanMarine: boolean
    returnPages: ReturnPage[]
    fieldTemplates: FieldTemplatesContainer[]
    filingAddresses: FilingAddressTemplate
    properties: ReturnProperty[]
}

// Need to go through the rest of the types in this file and make sure they are well defined

interface ReturnProperty {
    key: string
    value: string
}

export interface FieldTemplatesContainer {
    id: string
    numberFieldTemplates: NumberFieldTemplate[]
    textFieldTemplates: TextFieldTemplate[]
    checkFieldTemplates: CheckFieldTemplate[]
    labelFieldTemplates: LabelFieldTemplate[]
    signatureFieldTemplates: SignatureFieldTemplate[]
    comboBoxFieldTemplates: ComboBoxFieldTemplate[]
}

export interface ReturnPage {
    id: string
    attributes: PageAttributes
    subReturnPages: SubReturnPage[] // Is this actually used?
}

interface SubReturnPage {
    id: string
    attributes: PageAttributes
}

interface PageAttributes {
    caption: string
    pageOrder: number
    pageType: PageType
    pageInstance: number
    isRetal: boolean
    displayOrder: number
    isOkToPrint: boolean
    scale: number
    xOffset?: number
    yOffset?: number
    isVisible: boolean
    isDuplex: boolean
    pageOrientation: PageOrientation
    templateId: string
}

interface FilingAddressTemplate {
    filingAddressRows: FilingAddressParsedRow[]
}

interface FilingAddressParsedRow {
    filingAddresses: FilingAddress[]
    hasPaymentRequest: boolean
}

interface FilingAddress {
    addressOptionDescription: string
    addressOptionNumber: number
    city: string
    contactEmail: string
    contactName: string
    contactPhone: string
    payee: string
    state: string
    streetOne: string
    streetTwo: string
    website: string
    zip: string
}

export interface FieldTemplate {
    id: string
    fieldIndicator?: number
    screenBox?: ScreenBox
    isEnabled?: boolean
    isVisible?: boolean
    isOkToPrint?: boolean
    overrideState?: OverrideState
    fieldType?: string
}

export interface NumberFieldTemplate extends FieldTemplate {
    toolTip: string
    hyperlink: string
    isUrl: boolean
    style: Style
    tabIndex: number
    format: string[]
    numericFormatType: NumericFormatType
    spacingArray: string
    excludedChar: string[]
    maxLength: number
    isRetalAffected: boolean
    decimalPrecision: number
}

export interface TextFieldTemplate extends FieldTemplate {
    toolTip?: string
    hyperlink?: string
    isUrl?: boolean
    style?: Style
    tabIndex?: number
    format?: string
    textFormatType?: TextFormatType
    spacingArray?: string
    excludedChar?: string[]
    isMultiline?: boolean
    maxLength?: number
    isEncrypted?: boolean
    isTrimmed?: boolean
    isRetalAffected?: boolean
}

export interface CheckFieldTemplate extends FieldTemplate {
    toolTip: string
    hyperlink: string
    isUrl: boolean
    tabIndex: number
    checkFormatType: CheckFormatType
    isRetalAffected: boolean
}

export  interface LabelFieldTemplate extends FieldTemplate {
    style: Style
    textValue: string
    labelFormatType: LabelFormatType
}

interface SignatureFieldTemplate extends FieldTemplate {
    scale: number
}

export interface ComboBoxFieldTemplate extends FieldTemplate {
    toolTip: string
    hyperlink: string
    isUrl: boolean
    tabIndex: number
    isEditable: boolean
    style: Style
    isRetalAffected: boolean
    options: string[]
}

export interface ScreenBox {
    position: Position
    size: Size
}

interface Position {
    left: number
    top: number
}

interface Size {
    width: number
    height: number
}

interface Style {
    fontName?: string
    fontSize?: number
    bold?: boolean
    italic?: boolean
    underline?: boolean
    strikethrough?: boolean
    alignment?: Alignment
}

export enum Alignment {
    LEFT = "LEFT",
    RIGHT = "RIGHT",
    CENTER = "CENTER"
}

export enum PageType {
    NORMAL = "NORMAL",
    DYNAMIC = "DYNAMIC",
    SUMMARY = "SUMMARY",
    GRID = "GRID",
    MULTIINSTANCE = "MULTIINSTANCE",
    SUBSCHEDULE = "SUBSCHEDULE",
    ADJUSTMENT_SCHEDULE = "ADJUSTMENT_SCHEDULE",
    FILING_INFO_ANNUAL = "FILING_INFO_ANNUAL",
    FILING_INFO_ESTIMATE = "FILING_INFO_ESTIMATE"
}

export enum PageOrientation {
    LETTER_PORTRAIT = "LETTER_PORTRAIT",
    LEGAL_PORTRAIT = "LEGAL_PORTRAIT",
    LETTER_LANDSCAPE = "LETTER_LANDSCAPE",
    LEGAL_LANDSCAPE = "LEGAL_LANDSCAPE"
}

enum NumericFormatType {
    FORMAT_DOUBLE = "FORMAT_DOUBLE",
    FORMAT_PERCENT = "FORMAT_PERCENT",
    FORMAT_CURRENCY = "FORMAT_CURRENCY"
}

export enum TextFormatType {
    FORMAT_TEXT = "FORMAT_TEXT",
    FORMAT_DATE = "FORMAT_DATE",
    FORMAT_NUMBER_TEXT = "FORMAT_NUMBER_TEXT"
}

export enum CheckFormatType {
    X = "X",
    DARKEN = "DARKEN",
    Y_OR_N = "Y_OR_N",
    CHECKMARK = "CHECKMARK",
    CIRCLE = "CIRCLE"
}

export enum LabelFormatType {
    NONE = "NONE",
    LABEL = "LABEL",
    HEADER = "HEADER",
    FOOTER = "FOOTER"
}

export enum OverrideState {
    OVERRIDE_NEVER = "OVERRIDE_NEVER",
    OVERRIDE_ALWAYS = "OVERRIDE_ALWAYS",
    OVERRIDE_USER_CHOICE = "OVERRIDE_USER_CHOICE",
    OVERRIDE_GENERIC_FORMS = "OVERRIDE_GENERIC_FORMS"
}

enum DisplayUnit {
    TWIPS = "TWIPS",
    PIXELS = "PIXELS"
}
