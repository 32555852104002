import React, { FC } from "react";
import "./UploadTab.scss";
import { StyledUploadTab } from "./UploadTab.styled";
import { UploadFile } from "@mui/icons-material";
import colors from "src/styles/colors.scss";
import { DropEvent, ErrorCode, FileRejection, useDropzone } from "react-dropzone";

const ACCEPTED_FILE_TYPE = { "image/png": [".png"] };

interface UploadTabProps {
    index: number;
    value: number;
    isLoading: boolean;
    fileSignature: File | null;
    setFileSignature: (file: File) => void;
    handleSnackbar: (message: string | string[], severity: string, autoHideDuration?: number) => void;
}

const UploadTab: FC<UploadTabProps> = ({ value, index, isLoading, fileSignature, setFileSignature, handleSnackbar }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled: isLoading,
        multiple: false,
        accept: ACCEPTED_FILE_TYPE,
        onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent | Event) => {
            if (rejectedFiles?.length) {
                handleRejectedFileCode(rejectedFiles[0]?.errors[0]?.code);
            } else {
                handleUpload(acceptedFiles);
            }
        },
    });

    const acceptStyle = {
        border: `1px dashed ${colors.allocatorSelectedContainerBorderColor}`,
        backgroundColor: colors.allocatorComponentBackgroundColor,
    };

    const handleRejectedFileCode = (errorCode: ErrorCode | string) => {
        if (errorCode === ErrorCode.FileInvalidType) {
            handleSnackbar("Can't upload. Use a file in .png extension.", "error");
        } else {
            handleSnackbar("Can't upload file", "error");
        }
    };

    const checkSize = (files: File[]) => {
        const maxFilesSize = 50 * 1024; // 50 KB in bytes
        const filesSize = files?.reduce(
            (acc: number, file: File) => acc + file.size,
            0
        );

        if (filesSize > maxFilesSize) {
            handleSnackbar("The file size exceeds the 50kB limit. Please upload a smaller file", "error");
            return false;
        }

        return true;
    };

    const handleUpload = async (files: File[]) => {
        if (!checkSize(files)) {
            return;
        }
        
        setFileSignature(files[0]);
    };

    return (
        <StyledUploadTab
            role="tabpanel"
            hidden={value !== index}
            id={`signature-tabpanel-${index}`}
            aria-labelledby={`signature-tab-${index}`}
        >
            <div
                {...getRootProps({
                    className: "upload-tab-dropzone",
                    style: isDragActive ? acceptStyle : {},
                })}
            >
                <div className="upload-tab-content">
                    <input {...getInputProps()} />
                    {!fileSignature ? (
                        <>
                            <UploadFile
                                fontSize="medium"
                                style={{ color: colors.primaryColor }}
                            />
                            <p>Click to upload or drag and drop</p>
                            <p className="upload-tab-hint">
                                PNG (max. 50KB)
                            </p>
                        </>
                    ) : (
                        <img 
                            className="signature-preview-img" 
                            src={URL.createObjectURL(fileSignature)}
                        >
                        </img>
                    )}
                </div>
            </div>
        </StyledUploadTab>
    );
};

export default UploadTab;
