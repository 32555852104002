import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { MouseEvent, useState } from "react";
import colors from "src/styles/colors.scss";
import "./FolderNode.scss";
import FolderNodeMenu from "../FolderNodeMenu/FolderNodeMenu";
import { StyledAccordion, StyledAccordionDetails } from "./FolderNode.styled";
import {
    Company,
    FixMeLater,
    FolderNode as FolderNodeType,
    Product,
    ReturnNode as ReturnNodeType,
    State,
} from "src/types";
import { filterForReturnNodes } from "src/utils/TreeUtils";
import { useAppSelector } from "src/hooks";
import ReturnNode from "../ReturnNode/ReturnNode";
import { AccordionSummary } from "@mui/material";
import { generateUniqueKey } from "src/utils";

interface FolderNodeProps {
    expanded: boolean;
    folderNode: FolderNodeType;
    onFolderNodeClick: (id: string) => void;
    onFolderNodeDialogOpen: (folderNode: FixMeLater) => void;
}

const FolderNode: React.FC<FolderNodeProps> = ({
    expanded,
    folderNode,
    onFolderNodeClick,
    onFolderNodeDialogOpen,
}) => {
    const product: Product | undefined = useAppSelector(
        (state) => state?.Product?.value,
    );

    if (!product) return null; // We need to show a message when this happens

    const company: Company | null = useAppSelector(
        (state) => state[product?.productName]?.value?.company,
    );

    const state: State | undefined = useAppSelector(
        (state) => state[product!.productName]?.value.state,
    );

    const dropDownState: boolean = useAppSelector(
        (state) => state?.States?.dropDownState,
    );

    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value.selectedState
    );
    const selectedQuarter = useAppSelector(
        (state: FixMeLater) =>
            state?.[product?.productName]?.value?.selectedQuarter
    );

    // When the drop down is closed, and we don't have a company, we don't want to show the folder node
    if (!company && !dropDownState) return null; // We need to show a message when this happens

    // When the drop down is open, and we don't have a state, we don't want to show the folder node
    if (!state && dropDownState) return null; // We need to show a message when this happens

    const [folderNodeMenuAnchorEl, setFolderNodeMenuAnchorEl] =
        useState<FixMeLater>(null);

    const openFolderNodeMenu = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setFolderNodeMenuAnchorEl(event.currentTarget);
    };

    const handleCloseFolderNodeMenu = () => {
        setFolderNodeMenuAnchorEl(null);
    };

    const handleFolderNodeDialogOpen = (node: FixMeLater) => {
        setFolderNodeMenuAnchorEl(null);
        onFolderNodeDialogOpen(node);
    };

    const returnNodes = company
        ? folderNode?.returnNodes
              ?.filter((returnNode: ReturnNodeType) => {
                  return filterForReturnNodes(
                      returnNode,
                      folderNode,
                      company,
                      false,
                      true,
                  );
              })
              ?.map((returnNode: ReturnNodeType) => {
                return returnNode?.formStatus != "NONE" && (
                    <ReturnNode
                      key={returnNode.id}
                      returnNode={returnNode}
                      folderNode={folderNode}
                      tabKey={generateUniqueKey(folderNode,returnNode,product,company?.name,municipalState,selectedQuarter)}
                  />
              )}
            )
        : folderNode?.returnNodes
              ?.filter((returnNode: ReturnNodeType) => {
                  return filterForReturnNodes(
                      returnNode,
                      folderNode,
                      undefined,
                      false,
                      true,
                  );
              })
              .map((returnNode: ReturnNodeType) => (
                  <ReturnNode
                      key={returnNode.id}
                      returnNode={returnNode}
                      folderNode={folderNode}
                      tabKey={generateUniqueKey(folderNode,returnNode,product,folderNode?.attributes?.displayName,municipalState,selectedQuarter)}

                  />
              ));

    const handleContextMenu = (e: MouseEvent<HTMLElement>) => {
        if (folderNode?.attributes?.displayName !== "Company Information") {
            e.preventDefault(); // prevent the default behaviour when right clicked
            if (!dropDownState) {
                openFolderNodeMenu(e);
            }
        }
    };

    return (
        <div className="folder-node">
            {returnNodes?.length !== 0 ? (
                <StyledAccordion
                    key={folderNode?.id}
                    sx={{ background: "transparent" }}
                    expanded={expanded}
                    onClick={() => onFolderNodeClick(folderNode?.id as string)}
                >
                    <AccordionSummary
                        expandIcon={
                            <ExpandMoreIcon
                                style={{ color: colors.iconColor }}
                            />
                        }
                        id={`${folderNode?.id}-header`}
                        onContextMenu={handleContextMenu}
                    >
                        <div className="folder-node-container">
                            <div className="folder-node-name-container">
                                {folderNode?.attributes?.displayName}
                            </div>
                        </div>
                    </AccordionSummary>
                    <StyledAccordionDetails sx={{ color: colors.textColor }}>
                        {returnNodes}
                    </StyledAccordionDetails>
                </StyledAccordion>
            ) : (
                <StyledAccordion
                    key={folderNode?.id}
                    sx={{ background: "transparent" }}
                    expanded={false}
                    onClick={() => onFolderNodeClick(folderNode?.id as string)}
                >
                    <AccordionSummary
                        id={`${folderNode?.id}-header`}
                        onContextMenu={handleContextMenu}
                    >
                        <div className="folder-node-container">
                            <div className="folder-node-name-container">
                                {folderNode?.attributes?.displayName}
                            </div>
                        </div>
                    </AccordionSummary>
                </StyledAccordion>
            )}

            <FolderNodeMenu
                anchorEl={folderNodeMenuAnchorEl}
                onCloseFolderNodeMenu={handleCloseFolderNodeMenu}
                folderNode={folderNode}
                onFolderNodeDialogOpen={handleFolderNodeDialogOpen}
            />
        </div>
    );
};

export default FolderNode;
