import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EXPORT_FORMAT, PAYMENT_REQUEST_EXPORT_TAB } from "src/constants";
import {
    Company,
    FixMeLater,
    FolderNode,
    PaymentRequestExportState,
    ReturnDocument,
    ReturnDocumentNoteState,
    ReturnNode,
    ReturnPage,
    ReturnTemplate,
    Tree,
} from "src/types";

interface MunicipalState {
    value?: FixMeLater;
}

const initialState: MunicipalState = {
    value: {
        company: undefined,
        tree: undefined,
        selectedState: undefined,
        selectedQuarter: "Qtr 1",
        toogleFetch: false,
        toogleFetchSignature: false,
        paymentRequestExport: {
            open: false,
            selectedTab: PAYMENT_REQUEST_EXPORT_TAB.GENERAL,
            scheduleTemplateDTO: undefined,
            payload: {
                paymentTypes: [],
                minApproveDate: "",
                maxApproveDate: "",
                minDueDate: "",
                maxDueDate: "",
                dueDateRange: true,
                includeHeader: false,
                delimiter: ",",
                fixedWidth: [],
                minPaymentAmount: 0,
                exportInUpperCase: false,
                markAsPaid: false,
                fillDataPaidWithToday: false,
                useLeadingZero: false,
                fieldsToExport: [],
                exportFormat: EXPORT_FORMAT.DELIMITED,
            },
        },
        returnNotes: {
            newNoteFieldId: undefined,
            newNoteFieldType: undefined,
            showNotes: false,
            focusedFieldNoteId: undefined,
            focusedFieldNoteType: undefined,
        },
    },
};

export const MunicipalSlice = createSlice({
    name: "Municipal",
    initialState,
    reducers: {
        setCompany: (state, action: PayloadAction<Company>) => {
            state.value.company = action.payload;
        },
        setFolderNode: (state, action: PayloadAction<FolderNode>) => {
            state.value.folderNode = action.payload;
        },
        setReturnDocument: (state, action: PayloadAction<ReturnDocument>) => {
            state.value.returnDocument = action.payload;
        },
        setReturnTemplate: (state, action: PayloadAction<ReturnTemplate>) => {
            state.value.returnTemplate = action.payload;
        },
        setSelectedReturnPage: (state, action: PayloadAction<ReturnPage>) => {
            state.value.selectedReturnPage = action.payload;
        },
        setPaymentRequestExport: (
            state,
            action: PayloadAction<PaymentRequestExportState>,
        ) => {
            state.value.paymentRequestExport = action.payload;
        },
        setReturnNode: (state, action: PayloadAction<ReturnNode>) => {
            state.value.returnNode = action.payload;
        },
        setTree: (state, action: PayloadAction<Tree>) => {
            state.value.tree = action.payload;
        },
        setSelectedState: (
            state,
            action: PayloadAction<String | undefined>,
        ) => {
            state.value.selectedState = action.payload;
        },
        setSelectedQuarter: (
            state,
            action: PayloadAction<String | undefined>,
        ) => {
            state.value.selectedQuarter = action.payload;
        },
        setToogleFetch: (state) => {
            state.value.toogleFetch = !state.value.toogleFetch;
        },
        setToogleFetchSignature: (state) => {
            state.value.toogleFetchSignature = !state.value.toogleFetchSignature;
        },
        setReturnNotes: (
            state,
            action: PayloadAction<ReturnDocumentNoteState>,
        ) => {
            state.value.returnNotes = action.payload;
        },
    },
});

export const {
    setCompany,
    setSelectedState,
    setSelectedQuarter,
    setTree,
    setFolderNode,
    setReturnDocument,
    setReturnTemplate,
    setSelectedReturnPage,
    setPaymentRequestExport,
    setReturnNode,
    setToogleFetch,
    setToogleFetchSignature,
    setReturnNotes,
} = MunicipalSlice.actions;
export default MunicipalSlice.reducer;
