import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FC, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { ReturnDocumentNoteService } from "src/services";
import { getModuleId } from "src/services/Utility";
import {
    Company,
    FixMeLater,
    FolderNode,
    NoteFieldType,
    Product,
    ReturnDocument,
    ReturnDocumentNoteState,
    ReturnDocumentNoteType,
    ReturnDocumentNoteWithReturnIdentifierDTO,
    ReturnNode,
    ReturnPage,
    User,
} from "src/types";
import "./ScrollableNoteList.scss";
import {
    NoteTextWrapper,
    StyledAddButton,
    StyledBox,
    StyledCard,
    StyledCardContent,
    StyledCloseButton,
    StyledEditButton,
    StyledSaveCheckBox,
} from "./ScrollableNoteList.styled";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";

interface ScrollableNoteListProps {
    noteData: ReturnDocumentNoteType[];
    returnDocument: ReturnDocument | undefined;
    setReturnDocument: React.Dispatch<
        React.SetStateAction<ReturnDocument | undefined>
    >;
    product: Product;
    company: Company;
    folderNode: FolderNode;
    returnNode: ReturnNode;
    returnNotes: ReturnDocumentNoteState;
    municipalState: String;
}

const ScrollableNoteList: FC<ScrollableNoteListProps> = ({
    noteData,
    returnDocument,
    setReturnDocument,
    product,
    company,
    folderNode,
    returnNode,
    returnNotes,
    municipalState
}) => {

    const [editNoteIndex, setEditNoteIndex] = useState<number | null>(null);
    const [editNote, setEditNote] = useState<string>("");

    const [isAddingNewNote, setIsAddingNewNote] = useState<boolean>(false);
    const [newNote, setNewNote] = useState<string>("");

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [noteToRemove, setNoteToRemove] = useState<number | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const editNoteRef = useRef<HTMLDivElement>(null);

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const self: User | undefined = useAppSelector(
        (state) => state?.Self?.value,
    );

    const returnDocumentNoteService = ReturnDocumentNoteService.getInstance();

    const dispatch = useAppDispatch();

    const getUserName = (): string => {
        const hasValidName = (name: string | undefined) =>
            name && name.trim() !== "";

        if (
            hasValidName(self?.preferredName) ||
            hasValidName(self?.firstName)
        ) {
            return `${hasValidName(self?.preferredName) ? self?.preferredName : self?.firstName} ${self?.lastName}`;
        }

        if (self?.email) {
            return self.email.split("@")[0];
        }
        return "Unknown User";
    };

    const getTruncatedUserName = (name: string | undefined): string => {
        const truncateString = (str: string, maxLength: number): string => {
            if (str.length > maxLength) {
                return str.slice(0, maxLength) + "...";
            }
            return str;
        };

        if (name) {
            return truncateString(name, 8); // Truncate the name to 20 characters
        }
        return "Unknown User";
    };

    const handleRemoveCard = async (index: number) => {
        const note = noteData[index];
        try {
            await returnDocumentNoteService.deleteReturnFieldNote(note);
            const updatedNotes = noteData.filter(
                (_, noteIndex) => noteIndex !== index,
            );

            const returnDocumentWithUpdatedNotes = {
                ...returnDocument,
                returnNotes: updatedNotes,
            };

            setReturnDocument(returnDocumentWithUpdatedNotes);
        } catch (error) {
            handleSnackbar("Failed to delete note", "error");
        }
    };

    const handleEditClick = (index: number, note: string) => {
        setEditNoteIndex(index);
        setEditNote(note);
    };

    const handleEditChange = (event) => {
        setEditNote(event.target.value);
    };

    const handleEditSave = async () => {
        if (editNoteIndex !== null) {
            setIsLoading(true);
            try {
                const response =
                    await returnDocumentNoteService.editReturnFieldNote({
                        ...noteData[editNoteIndex],
                        noteText: editNote,
                        editedBy: getUserName(),
                        editedAt: new Date().getTime(),
                    });
                if (response) {
                    const updatedNotes = noteData.map((note, noteIndex) =>
                        noteIndex === editNoteIndex ? response : note,
                    );
                    setEditNoteIndex(null);
                    setEditNote("");

                    const returnDocumentWithUpdatedNotes = {
                        ...returnDocument,
                        returnNotes: updatedNotes,
                    };

                    dispatch(
                        GlobalStateActions?.[
                            product?.productName
                        ]?.setReturnNotes({
                            ...returnNotes,
                            focusedFieldNoteId: undefined,
                            focusedFieldNoteType: undefined,
                        }),
                    );

                    setReturnDocument(returnDocumentWithUpdatedNotes);
                }
            } catch (error) {
                handleSnackbar("Failed to edit note", "error");
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleNewNoteChange = (event) => {
        setNewNote(event.target.value);
    };

    const handleNewNoteSave = async () => {
        if (newNote.length > 0) {
            setIsLoading(true);
            try {
                const returnDocumentNoteWithReturnIdentifierDTO: ReturnDocumentNoteWithReturnIdentifierDTO =
                    {
                        companyId: company?.id,
                        productId: product?.productId,
                        taxYearId: product?.taxYear,
                        folderId: folderNode?.id as number,
                        moduleId: getModuleId(
                            product,
                            company,
                            municipalState, // If we are in Muni, we need the state to get the moduleId
                        ) as unknown as number,
                        returnId: returnNode?.id as number,
                        retalFolderId: returnNode.retalFolderId as number,
                        paymentRequestFormId: 0,
                        returnDocumentNoteEntity: {
                            createdBy: getUserName(),
                            noteText: newNote,
                            createdReturnId: -1, // set in the backend
                            pageId: returnNotes?.newFieldNotePage || 0,
                            fieldType: returnNotes?.newFieldNoteType || NoteFieldType.PAGE, // NoteFieldType.PAGE for page notes
                            fieldId: returnNotes?.newFieldNoteId || '-1', // -1 for page notes
                            createdAt: new Date().getTime(),
                            editedBy: getUserName(),
                            editedAt: new Date().getTime(),
                            id: undefined,
                        },
                    };

                const response =
                    await returnDocumentNoteService.createReturnNote(
                        returnDocumentNoteWithReturnIdentifierDTO,
                    );
                if (response) {
                    setIsAddingNewNote(false);
                    setNewNote("");

                    const returnDocumentWithUpdatedNotes = {
                        ...returnDocument,
                        returnNotes: [...noteData, response],
                    };

                    setReturnDocument(returnDocumentWithUpdatedNotes);

                    dispatch(
                        GlobalStateActions?.[
                            product?.productName
                        ]?.setReturnNotes({
                            newFieldNoteId: undefined,
                            newFieldNoteType: undefined,
                            showNotes: returnNotes?.showNotes,
                            focusedFieldNoteId: undefined,
                            focusedFieldNoteType: undefined,
                        }),
                    );
                }
            } catch (error) {
                console.error(error)
                handleSnackbar("Failed to add new note", "error");
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleDialogOpen = (index: number) => {
        setNoteToRemove(index);
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setNoteToRemove(null);
    };

    const handleConfirmRemove = () => {
        if (noteToRemove !== null) {
            handleRemoveCard(noteToRemove);
            setIsDialogOpen(false);
            setNoteToRemove(null);
        }
    };

    const onCardClickFocusField = (note: ReturnDocumentNoteType) => {
        if (note.fieldType === NoteFieldType.PAGE) return; // Do not focus on page notes
        dispatch(
            GlobalStateActions?.[product?.productName]?.setReturnNotes({
                ...returnNotes,
                focusedFieldNoteId: note.fieldId,
                focusedFieldNoteType: note?.fieldType,
            }),
        );
    };

    const pageFilteredNotes = noteData
        .filter((note: ReturnDocumentNoteType) => {
            if (
                returnNotes?.focusedFieldNoteId === undefined ||
                returnNotes?.focusedFieldNoteType === undefined
            )
                return true;
            else
                return (
                    note.fieldId === returnNotes?.focusedFieldNoteId &&
                    note.fieldType === returnNotes?.focusedFieldNoteType
                );
        });

    return (
        <div className="scrollable-note-list-container">
            <StyledAddButton
                aria-label="add"
                onClick={() => {
                    setIsAddingNewNote(true);
                }}
                disabled={
                    isAddingNewNote ||
                    (returnNotes?.newFieldNoteId as unknown as boolean)
                }
            >
                <AddCircleOutlineIcon />
            </StyledAddButton>
            <StyledBox>
                {(isAddingNewNote || returnNotes?.newFieldNoteId) && (
                    <StyledCard data-testid="new-note-card">
                        <StyledCloseButton
                            aria-label="close"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents note click when closing
                                setIsAddingNewNote(false);
                                setNewNote("");
                                setEditNoteIndex(null);
                                setEditNote("");
                                dispatch(
                                    GlobalStateActions?.[
                                        product?.productName
                                    ]?.setReturnNotes({
                                        newFieldNoteId: undefined,
                                        newFieldNoteType: undefined,
                                        showNotes: returnNotes?.showNotes,
                                        focusedFieldNoteId: undefined,
                                        focusedFieldNoteType: undefined,
                                    }),
                                );
                            }}
                        >
                            <CloseIcon className="note-icon" />
                        </StyledCloseButton>

                        <StyledCardContent>
                            <Typography variant="body2" color="textSecondary">
                                {getTruncatedUserName(getUserName())} -{" "}
                                {new Date().toLocaleDateString()}
                            </Typography>

                            <div style={{ display: "flex" }}>
                                <TextField
                                    value={newNote}
                                    onChange={(e) => {
                                        e.stopPropagation(); // Prevents note click when typing
                                        handleNewNoteChange(e);
                                    }}
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginRight: "8px" }}
                                    onClick={(e) => e.stopPropagation()}
                                />

                                <StyledSaveCheckBox
                                    aria-label="save"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevents note click when saving
                                        handleNewNoteSave();
                                    }}
                                >
                                    {isLoading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        <CheckIcon data-testid="save-icon" />
                                    )}
                                </StyledSaveCheckBox>
                            </div>
                        </StyledCardContent>
                    </StyledCard>
                )}
                {pageFilteredNotes.map((note, index) => (
                    <StyledCard
                        key={index}
                        $noteType={note?.fieldType}
                        onClick={() => onCardClickFocusField(note)}
                        data-testid="note-card"
                    >
                        <StyledCloseButton
                            aria-label="close"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents note click when closing
                                setEditNoteIndex(null);
                                setEditNote("");
                                handleDialogOpen(index);
                            }}
                        >
                            <CloseIcon className="note-icon" />
                        </StyledCloseButton>

                        <StyledEditButton
                            aria-label="edit"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents note click when editing
                                handleEditClick(index, note?.noteText);
                            }}
                        >
                            <EditIcon className="note-icon" />
                        </StyledEditButton>

                        <StyledCardContent>
                            {
                                // If the note was edited, show the edited by and edited at
                                note?.createdAt !== note?.editedAt ? (
                                    <Tooltip
                                        title={
                                            <>
                                                <Typography variant="body2">
                                                    Created by:{" "}
                                                    {note?.createdBy}
                                                </Typography>
                                                <Typography variant="caption">
                                                    Created at:{" "}
                                                    {new Date(
                                                        note?.createdAt,
                                                    ).toLocaleDateString()}
                                                </Typography>
                                            </>
                                        }
                                    >
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            {getTruncatedUserName(
                                                note?.editedBy,
                                            )}{" "}
                                            -{" "}
                                            {new Date(
                                                note?.editedAt,
                                            ).toLocaleDateString()}{" "}
                                            (edited)
                                        </Typography>
                                    </Tooltip>
                                ) : (
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        {getTruncatedUserName(note?.createdBy)}{" "}
                                        -{" "}
                                        {new Date(
                                            note?.createdAt,
                                        ).toLocaleDateString()}
                                    </Typography>
                                )
                            }

                            {editNoteIndex === index ? (
                                <div className="flex-container">
                                    <TextField
                                        value={editNote}
                                        onChange={(e) => {
                                            e.stopPropagation(); // Prevents note click when typing
                                            handleEditChange(e);
                                        }}
                                        variant="outlined"
                                        size="small"
                                        sx={{ marginRight: "8px" }}
                                        onClick={(e) => e.stopPropagation()}
                                        ref={editNoteRef}
                                        data-testid="edit-note-text-field"
                                    />

                                    <StyledSaveCheckBox
                                        aria-label="save"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevents note click when saving
                                            handleEditSave();
                                        }}
                                    >
                                        {isLoading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <CheckIcon data-testid="edit-save-icon" />
                                        )}
                                    </StyledSaveCheckBox>
                                </div>
                            ) : (
                                <NoteTextWrapper>
                                    {note?.noteText}
                                </NoteTextWrapper>
                            )}
                        </StyledCardContent>
                    </StyledCard>
                ))}

                {pageFilteredNotes?.length === 0 &&
                    !isAddingNewNote &&
                    !returnNotes?.newFieldNoteId && (
                        <StyledCard>
                            <StyledCardContent>
                                <NoteTextWrapper>
                                    No notes found!
                                </NoteTextWrapper>
                            </StyledCardContent>
                        </StyledCard>
                    )}
            </StyledBox>

            <Dialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this note?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmRemove}
                        color="primary"
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </div>
    );
};

export default ScrollableNoteList;
