import { JOB_STATUS_TYPES } from "src/types";

export const ACCEPTED_FILE_TYPES = {
    "text/csv": [],
    "text/plain": [],
    "application/vnd.ms-excel": [],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
};

export const ACCEPTED_SETTINGS_TYPES = {
    "application/vnd.ms-excel": [],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
};

export const DEFAULT_SEARCH_PARAMS = {
    summary: "0",
    report: "1", 
    page: "0", 
    size: "100",
}

export const STATE_OPTIONS = [
    { value: "KY", label: "Kentucky" },
    { value: "FL", label: "Florida" },
]

export const QUARTER_OPTIONS = [
    { value: "1", label: "Q1" },
    { value: "2", label: "Q2" },
    { value: "3", label: "Q3" },
    { value: "4", label: "Q4" },
]

export const JOB_STATUS_OPTIONS = [
    { value: JOB_STATUS_TYPES.PROCESSING, label: "Processing" },
    { value: JOB_STATUS_TYPES.PROCESSED,  label: "Processed" },
    { value: JOB_STATUS_TYPES.ON_REVIEW,  label: "Under Review" },
    { value: JOB_STATUS_TYPES.APPROVED,  label: "Approved" },
    { value: JOB_STATUS_TYPES.CANCELED,  label: "Canceled" },
    { value: JOB_STATUS_TYPES.FAILED,  label: "Errors" },
]