import React from "react";

const RETURN = "RETURN";
const NOT_UPDATED = "NOT_UPDATED";
const UPDATED = "UPDATED";
const PLACE = "PLACE";
const REPORT = "REPORT";

const getImagePath = (formStatus, isLocked) => {
    const lockedSuffix = isLocked ? "-locked" : "";

    switch (formStatus) {
        case RETURN:
            return `regular-form${lockedSuffix}.ico`;
        case NOT_UPDATED:
            return `grey-circle${lockedSuffix}.ico`;
        case UPDATED:
            return `regular-form${lockedSuffix}.ico`;
        case PLACE:
            return `grey-circle${lockedSuffix}.ico`;
        case REPORT:
            return `report${lockedSuffix}.ico`;
        default:
            return `schedule${lockedSuffix}.ico`;
    }
};

export const getReturnNodeIcon = (formStatus, isLocked) => {
    const imagePath = getImagePath(formStatus, isLocked);
    return (
        <img
            className="return-icon"
            src={require(`../../assets/images/return-icons/${imagePath}`)}
            alt={`${formStatus?.toLowerCase()} icon`}
        />
    );
};
