import React, { FC, useState } from "react";
import { DropEvent, ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { Upload } from "@mui/icons-material";
import Loader from "src/components/Loader/Loader";
import { GraphqlService, ScheduleService } from "src/services";
import colors from "src/styles/colors.scss";
import { FixMeLater, Tree } from "src/types";
import "./ImportPremiumData.scss";
import { ACCEPTED_FILE_TYPES, getErrorMessageByCode } from "./ImportPremiumData.util";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { FormControlLabel, Radio, Typography } from "@mui/material";
import { HttpError } from "src/utils/HttpError";
import { StyledRadioGroup } from "./ImportPremiumData.styled";
import { returnTreeQuery } from "src/services/GQLQueries";

interface ScheduleInput {
    companyId: string;
    moduleId: string;
    productId: string;
    taxYearId: string;
    returnId: string;
    folderId: string;
    pageNumber: string;
}

interface ImportPremiumDataProps {
    scheduleInput: ScheduleInput;
    onClose: (e: DropEvent | Event) => void;
    handleSnackbar: (message: string | string[], severity: string, autoHideDuration?: number) => void;
}

const ImportPremiumData: FC<ImportPremiumDataProps> = ({scheduleInput, onClose, handleSnackbar}) => {
    const scheduleService = ScheduleService.getInstance();
    const graphqlService = GraphqlService.getInstance();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [importOption, setImportOption] = useState<string>("replace");

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    const dispatch = useAppDispatch();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled: isLoading,
        multiple: false,
        accept: ACCEPTED_FILE_TYPES,
        onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent | Event) => {
            if (rejectedFiles?.length) {
                handleRejectedFileCode(rejectedFiles[0]?.errors[0]?.code, event ?? new Event("importPremiumData"));
            } else {
                handleUpload(acceptedFiles, event ?? new Event("importPremiumData"));
            }
        },
    });

    const acceptStyle = {
        border: `1px dashed ${colors.allocatorSelectedContainerBorderColor}`,
        backgroundColor: colors.allocatorComponentBackgroundColor,
    };

    const handleRejectedFileCode = (errorCode: ErrorCode | string, event: DropEvent | Event) => {
        onClose(event);
        handleSnackbar(getErrorMessageByCode(errorCode), "error");
    };

    const handleUpload = async (files: File[], event: DropEvent | Event) => {
        setIsLoading(true);

        const formData = new FormData();
        formData.append("file", files[0], files[0].name)
        const attachmentMetadata = new Blob(
            [
                JSON.stringify(
                    scheduleInput
                ),
            ],
            { type: "application/json" },
        );

        formData.append("scheduleInput", attachmentMetadata);

        try {
            const response = await scheduleService.uploadPremiumData(formData, importOption);
            handleSnackbar(response, "success", 30000);
            dispatch(GlobalStateActions[product?.productName].setToogleFetch());
            const treeInput = {
                companyId: scheduleInput?.companyId,
                productId: product?.productId,
                taxYearId: product?.taxYear,
                moduleId: scheduleInput?.moduleId,
            };

            const { tree } = await graphqlService.fetchData<{ tree: Tree }>(returnTreeQuery, { treeInput });

            dispatch(
                GlobalStateActions[product?.productName].setTree(tree)
            );
        } catch (error: FixMeLater) {
            if (error instanceof HttpError) {
                handleSnackbar(JSON.parse(error?.body)?.message, "error");
            } else {
                handleSnackbar("Error uploading file", "error");
            }
        } finally {
            setIsLoading(false);
            onClose(event);
        }
    };

    return (
        <div className="import-premium-data-container">
            <Typography sx={{ marginY: 1 }}>
                Select how you want to proceed if some data you are importing already exists in the Input Schedule
            </Typography>
            <StyledRadioGroup
                value={importOption}
                onChange={(e) => setImportOption(e?.target?.value)}
            >
                <FormControlLabel
                    value={"replace"}
                    control={<Radio />}
                    label="Import data from the file only"
                    disabled={isLoading}
                />
                <FormControlLabel
                    value={"clear"}
                    control={<Radio />}
                    label="Clear all data and import"
                    disabled={isLoading}
                />
            </StyledRadioGroup>
            <div
                {...getRootProps({
                    className: "import-premium-data-dropzone",
                    style: isDragActive ? acceptStyle: {},
                })}
            >
                {isLoading ? (
                    <Loader color={colors.primaryColor}/>
                ) : (
                    <div className="import-premium-data-content">
                        <input {...getInputProps()} />
                        <Upload
                            fontSize="medium"
                            style={{ color: colors.primaryColor }}
                        />
                        {isDragActive ? (
                            <p>Drop a document here</p>
                        ) : (
                            <p>Click or drag ASCII (.imp or .txt) file</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImportPremiumData;
