import React, { FC, useEffect, useState } from "react";
import MultiTab from "src/components/MultiTab/MultiTab";
import { Sync, SyncDisabled } from "@mui/icons-material";
import "./MultiTabContainer.scss";
import { Tooltip } from "@mui/material";
import { ScrollSync } from "react-scroll-sync";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";

const MultiTabContainer: FC = () => {
    const [synchronized, setSynchronized] = useState<boolean>(false);

    const [activeReturn, setActiveReturn] = useState<string>("");
    const [activeReturnSplitScreen, setActiveReturnSplitScreen] =
        useState<string>("");

    const [tabs, setTabs] = useState<Map<string, Tab>>(new Map());

    const [tabsSplitScreen, setTabsSplitScreen] = useState<Map<string, Tab>>(
        new Map(),
    );

    const [activeTabKey, setActiveTabKey] =
    useState<string>("");

    const splitScreen = useAppSelector((state) => state.Tabs.splitScreen);

    const dispatch = useAppDispatch();

    const addTabToSplitScreen = (mapKey: string) => {
        const tab = tabs.get(mapKey);
        if (tab) {
            setTabsSplitScreen(new Map(tabsSplitScreen).set(mapKey, tab));
            // Set the key to the new tab
            setActiveTabKey(mapKey);
        }
    };

    useEffect(() => {
        if (tabsSplitScreen.size === 0 || tabs.size === 0) {
            dispatch(GlobalStateActions.setSplitScreen(false));
            setActiveReturnSplitScreen("")
            setActiveReturn("")
        }
    }, [tabsSplitScreen, tabs]);

    return (
        <ScrollSync enabled={splitScreen && synchronized && activeReturn === activeReturnSplitScreen}>
            <div data-testid="multi-tab-container" className="container">
                <div
                    data-testid="main-tab"
                    className="main-tab"
                    style={{ width: splitScreen ? "50%" : "100%" }}
                >
                    <MultiTab
                        tabs={tabs}
                        setTabs={setTabs}
                        addTabToSplitScreen={addTabToSplitScreen}
                        setActiveReturn={setActiveReturn}
                    />
                </div>
                {splitScreen && (
                    <>
                        <div className="split-tab">
                            <MultiTab
                                isSplitedScreen={true}
                                tabs={tabsSplitScreen}
                                setTabs={setTabsSplitScreen}
                                addTab={false}
                                setActiveReturn={setActiveReturnSplitScreen}
                                activeTabKey={activeTabKey}
                            />
                        </div>
                        {activeReturn === activeReturnSplitScreen && (
                            <Tooltip
                                title={
                                    synchronized ? "desyncronize" : "syncronize"
                                }
                            >
                                <button
                                    data-testid="sync-button"
                                    onClick={() =>
                                        setSynchronized(!synchronized)
                                    }
                                    className="sync-button"
                                >
                                    {synchronized ? (
                                        <SyncDisabled className="sync-icon" />
                                    ) : (
                                        <Sync className="sync-icon" />
                                    )}
                                </button>
                            </Tooltip>
                        )}
                    </>
                )}
            </div>
        </ScrollSync>
    );
};

export default MultiTabContainer;

// Define the Tab type
interface Tab {
    key: string;
    tabName: string;
    taxYear: string;
    companyName: string;
    tooltip: string;
    scheduleType: string;
    isLocked: boolean;
    component: React.ReactElement;
}
