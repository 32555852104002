import colors from "src/styles/colors.scss";

class DinamicColors {
    // Function to calculate dynamic background color based on conditions
    static calculateDynamicBackgroundColor = (
        isEnabled,
        overrideState
    ): string => {
        // If the input is not enabled, use a specific background color
        if (!isEnabled) {
            return colors.textFieldUnableBackgroundColor;
        }
        // Use different background colors based on overrideState conditions
        if (
            overrideState === "OVERRIDE_NEVER" ||
            overrideState === "OVERRIDE_USER_CHOICE"
        ) {
            return colors.textFieldOverrideNeverBackgroundColor;
        } else {
            return colors.textFieldOverrideAlwaysBackgroundColor;
        }
    };

    // Function to calculate dynamic text color based on conditions
    static calculateDynamicTextFieldColor = (
        isEnabled,
        overrideState,
        isOverride
    ): string => {
        // If the input is not enabled, use a specific text color
        if (!isEnabled) {
            return colors.disabledColor;
        }

        // Use different text colors based on overrideState and value conditions
        if (
            overrideState === "OVERRIDE_NEVER" ||
            overrideState === "OVERRIDE_USER_CHOICE"
        ) {
            return colors.textFieldValueColor;
        } else if (isOverride) {
            return colors.textFieldOverrideValueColor;
        } else {
            return colors.textFieldValueColor;
        }
    };

    // Function to calculate dynamic Hyperlink border color based on conditions
    static calculateDynamicTextFieldBorderColor = (
        hyperlink: string,
    ): string => {
        if(hyperlink.startsWith("SW")){
            return colors.SupportingHyperLinkBorderColor
        }
        return colors.OutsideHyperLinkBorderColor;
    };
}

export default DinamicColors;
